import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface UserInterface {
    email: string | null
    id: string | null
}

const initialState: UserInterface = {
    email: null,
    id: null,
};

interface SetUserPayload {
    email: string | null;
    id: string | null 
}

const userAuthSlice = createSlice({
    name: 'authUser',
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<SetUserPayload>) {
            state.email = action.payload.email;
            state.id = action.payload.id;
        },
        removeUser(state) {
            state.email = null;
            state.id = null;
        },
    },
});

export const {setUser, removeUser} = userAuthSlice.actions;

export default userAuthSlice.reducer;