import { createSlice } from '@reduxjs/toolkit';
import { User } from "../../api/interface";

interface UsersState {
    users: User[]
}

const initialState: UsersState = {
    users: [
        {
            id: 'FGNTMtdZJuZ5TinR9sJHrf5nPED2',
            name: 'Иван',
            surName: 'Абрамов',
            fatherName: 'Александрович',
            value: 'FGNTMtdZJuZ5TinR9sJHrf5nPED2',
            label: 'Иван Абрамов',
            you: true,
            specs: 'IT-отдел',
        },
    
        {
            id: 'XkIjsIdw69ghLudgDDztks3g23S2',
            name: 'Георгий',
            surName: 'Мороз',
            fatherName: 'Святославович',
            value: 'XkIjsIdw69ghLudgDDztks3g23S2',
            label: 'Георгий Мороз',
            you: false,
            specs: 'Судебный отдел',
        },
    
        {
            id: '1zNyYjM7AKgkkIYvBI3d2tB4kJ43',
            name: 'Святослав',
            surName: 'Мороз',
            fatherName: 'Георгиевич',
            value: '1zNyYjM7AKgkkIYvBI3d2tB4kJ43',
            label: 'Святослав Мороз',
            you: false,
            specs: 'CEO',
        },

    
        {
            id: '7nfPePKd9Zhde2DiUjhHFiEYNT03',
            name: 'Елена',
            surName: 'Калиниченко',
            fatherName: 'Геннадьевна',
            value: '7nfPePKd9Zhde2DiUjhHFiEYNT03',
            label: 'Елена Калиниченко',
            you: false,
            specs: 'Абонентский отдел',
        },
    
        {
            id: 'NklLF4Pu7qe0iFQ1CHycYaGrzbF2',
            name: 'Елена',
            surName: 'Батухтина',
            fatherName: 'Олеговна', 
            value: 'NklLF4Pu7qe0iFQ1CHycYaGrzbF2',
            label: 'Елена Батухтина',
            you: false,
            specs: 'Абонентский отдел',
        },
    
        {
            id: 'kmHouolAUiZmtGX7q4r4pQWafLj2',
            name: 'Дарья',
            surName: 'Печерская',
            fatherName: 'Алексеевна',
            value: 'kmHouolAUiZmtGX7q4r4pQWafLj2',
            label: 'Дарья Печерская',
            you: false,
            specs: 'Ассистент CEO',
        },

        {
            id: '9',
            name: 'Виталий',
            surName: 'Вольский',
            fatherName: 'Владимирович',
            value: '9',
            label: 'Виталий Вольский',
            you: false,
            specs: 'Оценщик',
        },

        {
            id: '10',
            name: 'Сергей',
            surName: 'Терехов',
            fatherName: 'Викторович',
            value: '10',
            label: 'Сергей Терехов',
            you: false,
            specs: 'Кадастровый отдел',
        },

        {
            id: '11',
            name: 'Алексей',
            surName: 'Кузнецов',
            fatherName: 'Леонидович',
            value: '11',
            label: 'Алексей Кузнецов',
            you: false,
            specs: 'Кадастровый отдел',
        },

        {
            id: 'YXcn0MZSv5Y1KSc2PzIaeDA1Hlv1',
            name: 'Анна',
            surName: 'Тихонова',
            fatherName: 'Александровна',
            value: 'YXcn0MZSv5Y1KSc2PzIaeDA1Hlv1',
            label: 'Анна Тихонова',
            you: false,
            specs: 'Абонентский отдел',
        },

        {
            id: 'KQydQPbF73YFmJeUB6L5thnsUnq2',
            name: 'Тимофей',
            surName: 'Ветошкин',
            fatherName: 'Александрович',
            value: 'KQydQPbF73YFmJeUB6L5thnsUnq2',
            label: 'Тимофей Ветошкин',
            you: false,
            specs: 'Судебный отдел',
        },
        {
            id: 'kwsPA22rmJQ3y6398tuqe6N0bX62',
            name: 'Ярослава',
            surName: 'Кудашова',
            fatherName: 'Романовна',
            value: 'kwsPA22rmJQ3y6398tuqe6N0bX62',
            label: 'Ярослава Кудашова',
            you: false,
            specs: 'Судебный отдел',
        },
        {
            id: 'Jp2dUsoQqKQ7gpRccwj9693G4Rt2',
            name: 'Александр',
            surName: 'Барабаш',
            fatherName: 'Михайлович',
            value: 'Jp2dUsoQqKQ7gpRccwj9693G4Rt2',
            label: 'Александр Барабаш',
            you: false,
            specs: 'Судебный отдел',
        },
    ]
}

export const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers:{}
})

export default userSlice.reducer