import {FC} from "react";
import { UsersProps } from "./interface";
import { UserCard } from "../../ui/UserUi/UserCard";
import classes from './users.module.scss'
import { useParams } from 'react-router-dom';
import { UserScreen } from "../../ui/UserUi/UserScreen";
import { useAppSelector } from "../../store/reduxHooks";

export const Users: FC<UsersProps> = (props) => {
    const { id } = useParams();
    const users = useAppSelector(state => state.users.users);
    const user = users.find((user) => user.id === id);
    const myUser = useAppSelector(state => state.authuser.id)
    const usersWithoutYou = users.filter((user) => user.id !== myUser)

    
  return (
  <div className={classes.root}>
    <div className={classes.users}>
        {usersWithoutYou.map((user) => (
          <UserCard data={user} userId={user.id} key={user.id} />
        ))}

    </div>
      
    <div className={classes.screen}>
          {user ? <UserScreen data={user} {...user} /> : null}
    </div>
  </div>
    
  )
}
