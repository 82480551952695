import { useCallback } from "react";
import { addDoc, arrayRemove, arrayUnion, collection, doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase.config";
import { useGetClients } from "./useGetDocs";
import { v4 as uuid } from 'uuid';


export function useHandleAddClients(
    clientName: string, 
    clientPhone: string, 
    clientINN: string, 
    clientOGRN: string, 
    clientKPP: string,
    clientType: string
) 
{
    const getMyDocs = useGetClients();

    return useCallback(() => {
        addDoc(collection(db, 'clients'), {
            name: clientName,
            phone: clientPhone,
            inn: clientINN,
            ogrn: clientOGRN,
            kpp: clientKPP,
            clientType: clientType,
            isActive: true,
            isArchived: false,
            isDeleted: false,
            contacts: [],
            cases: [],
        }).then(getMyDocs)
    }, [clientINN, clientKPP, clientName, clientOGRN, clientPhone, clientType, getMyDocs])
}

export function useHandleDeleteCLients(id: string) {
    const getMyDocs = useGetClients();
    return () => {
        updateDoc(doc(db, 'clients', `${id}`), {
            isDeleted: true,
            isActive: false
        })
        .then(getMyDocs)
    }
}

export function useHandleArchiveClients(id: string) {
    const getMyDocs = useGetClients();
    return () => {
        updateDoc(doc(db, 'clients', `${id}`), {
            isArchived: true,
            isActive: false
        })
        .then(getMyDocs)
    }
}

export function useHandleAddContacts(id: string, 
    contactName: string,
    contactJob: string,
    contactMail: string,
    contactPhone: string,) {
    const getMyDocs = useGetClients();
    return useCallback(() => {
        const UniqeId = uuid()
        const newContactItem = {
            id: UniqeId,
            name: contactName,
            job: contactJob,
            phone: contactPhone,
            mail: contactMail,
        }
            updateDoc(doc(db, 'clients', `${id}`), {
                contacts: arrayUnion(newContactItem),
            }).then(getMyDocs)
    }, [contactJob, contactMail, contactName, contactPhone, getMyDocs, id])    
}

export function useHandleDeleteContacts(
    id: string | undefined  ,
    contactName: string,
    contactJob: string,
    contactMail: string,
    contactPhone: string,
    constactId: string) {
    const getMyDocs = useGetClients();
    return useCallback(() => {
        const newContactItem = {
            id: constactId,
            name: contactName,
            job: contactJob,
            phone: contactPhone,
            mail: contactMail,
        }
            updateDoc(doc(db, 'clients', `${id}`), {
                contacts: arrayRemove(newContactItem),
            }).then(getMyDocs)
    }, [constactId, contactJob, contactMail, contactName, contactPhone, getMyDocs, id])    
}