import {FC} from 'react';
import { DeletedCaseScreenProps } from './interface';
import classes from './archivedcasescreen.module.scss'
import { useAppDispatch, useAppSelector } from '../../../store/reduxHooks';
import { UserCard } from '../../UserUi/UserCard';
import { MdCalendarMonth } from 'react-icons/md';
import { TodoClientItem } from '../../Clientui/TodoClientItem';
import { BillingItem } from '../../../components/BillingItem';
import {RiInformationLine} from 'react-icons/ri';
import {SlDocs} from 'react-icons/sl';
import {HiOutlineCheckCircle} from 'react-icons/hi2';
import {BiArchiveOut} from 'react-icons/bi';
import { useNavigate } from 'react-router';



export const DeletedCaseScreen:FC <DeletedCaseScreenProps> = (props) => {
    const { data} = props;
    const { id, name, clientId, } = data;
    const users = useAppSelector(state => state.users.users);
    const dispatch = useAppDispatch();
    const navigate = useNavigate()

  return (  
    <div className={classes.root}>
         <div className={classes.header}>
            <div className={classes.task}>
                <div className={classes.task}>
                    {}
                </div>

            </div>
            <div className={classes.clientRoot}>
                <div className={classes.client}>
                    {name}

                </div>
                <div className={classes.buttonBox}>
                    <div className={classes.button} title='Завершить дело' >
                        <HiOutlineCheckCircle size={22}/>
                    </div>
                    <div className={classes.button} title='Информация'>
                        <RiInformationLine size={22}/>
                    </div>
                    <div className={classes.button} >
                        <SlDocs size={22} title='Документы'/>
                    </div>
                    <div className={classes.button} >
                        <BiArchiveOut size={22} title='Восстановить' />
                    </div>
                </div>
            </div>
        </div>
        <div className={classes.section}>
            <div className={classes.users}>
                <div className={classes.userBox}>
                </div>
            </div>
            <div className={classes.calendar}>
                <MdCalendarMonth size={150} />
            </div>
        </div> 

        {/* <div className={classes.content}>
            <div className={classes.todos}>
                {tasks.map((task) => (
                    <TodoClientItem 
                    clientId={clientId} 
                    data={task} 
                    key={task.id} 
                    caseId={id}
                    />  
                ))}  
            </div>
            <div className={classes.todos}>

            </div>
        </div> */}

    </div>
  )
}
