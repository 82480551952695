import {FC, useState, useEffect} from 'react';
import { ChatScreenProps } from './interface';
import classes from './chatscreen.module.scss'
import { useAppDispatch, useAppSelector } from '../../../store/reduxHooks';
import { useNavigate, useParams } from 'react-router';
import { Message } from '../Message';
import {TbMessageCircle} from 'react-icons/tb';
import {RiUserFollowLine} from 'react-icons/ri'
import { ChatInput } from '../ChatInput';
import { addMail } from '../../../store/slices/chatSlice';
import { useHandleUpdateChats } from '../../../hooks/useChats';

export const ChatScreen: FC<ChatScreenProps> = (props) => {
  const {data} = props;
  const {title} = data;
  const {id} = useParams();
  const dispatch = useAppDispatch();
  const user = useAppSelector(state => state.authuser)
  const myId = user.id
  const chatId = id

  
  const [text, setText] = useState<string>([] as any);

  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/users/${id}`)
  }
  const chats = useAppSelector(
    (state) => state.chats.find((pack) => pack.id === id)?.messages
  );
  const messages = chats

  const updateChats = useHandleUpdateChats(chatId, messages)
    
  const addMessage = () => { 
    return () => {
      if (text.trim().length) {
        dispatch(addMail({text, id: id as string, myId: myId as string}))
        setText('')
      }
    }
  };
  useEffect(() => {
    updateChats()
    console.log('updateChat222')

  }, [messages])


  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.headerTitle}>
          <TbMessageCircle className={classes.icon} size={25} />
          {title}
        </div>
        <div className={classes.headerIcons}>
          <RiUserFollowLine size={25} onClick={handleClick}/>
        </div>
        
      </div>
      <div className={classes.chat}>
        {chats?.map((chat) =>(
          <Message data={chat} key={chat.id} />
        ))}
      </div>
      <div className={classes.input}>
        <ChatInput 
          value={text}
          handleInput={setText}
          handleSubmit={addMessage()}
          placeholder={'Enter your message'}
          buttonHolder={'Отправить'}
          />
      </div>
    </div>
  )
}