import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChatListItemData } from '../../api/interface';

interface AddMailActionPayload {
    id: string,
    text: string,
    myId: string
}
const initialChatState: ChatListItemData[] = []
const initialState: ChatListItemData[] = initialChatState;

export const chatSlice = createSlice({
    name: 'chats',
    initialState,
    reducers:{
        getChats(state, action) {
            return action.payload
          },
        addMail(state, action: PayloadAction<AddMailActionPayload>) {
            const messageIndex = state.findIndex(
                (chat: { id: string; }) => chat.id === action.payload.id
            );
            if (messageIndex > -1) {
                const UniqeId = new Date().toISOString();
                state[messageIndex].messages.push({
                    id: UniqeId,
                    message: action.payload.text,
                    userId: action.payload.myId
                })
            }
        }
     }
});

export const {addMail, getChats} = chatSlice.actions;
export default chatSlice.reducer