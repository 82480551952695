import {FC, useState, useEffect, useCallback} from 'react';
import { AddDisputeFormProps } from './intrface';
import classes from './adddisputeform.module.scss'
import { MyInput } from '../MyInput';
import Select from 'react-select'
import { selectTagsOptions } from '../../api/data';
import { useMyUser } from '../../hooks/useUsers';
import { useHandleAddDisputes } from '../../hooks/useCase';
import { useAppSelector } from '../../store/reduxHooks';

export const AddDisputeForm: FC <AddDisputeFormProps> = (props) => {
    const {handleClose, clientId, caseId} = props;

    const [disputeName, setDisputeName] = useState<string>([] as any);
    const [caseTask, setCaseTask] = useState<string>([] as any);
    const [caseNumber, setCaseNumber] = useState<string>([] as any);
    const [caseAnnotation, setCaseAnnotation] = useState<string>([] as any); 
    const [paymentSystem, setPaymentSystem] = useState<string>([] as any);
    const [billSum, setBillSum] = useState<number>(0);
    const [courtPrice, setCourtPrice] = useState<number>([] as any);
    const [lawyerPrice, setLawyerPrice] = useState<number>([] as any);
    const [compensationSystem, setCompensationSystem] = useState<string>([] as any);
    const [tagtoCase, setTagtoCase] = useState<any>([] as any)
    const myCaseTag = tagtoCase.value

    const user = useAppSelector(state => state.authuser)
    const userId = user.id
    const users = useAppSelector(state => state.users.users)
    const myUser = users.find((user) => user.id === userId)
    const myUserId = `${myUser?.id}`
    const creatorUserId = userId
    
    const addDispute = useHandleAddDisputes(
        clientId,
        caseId,
        disputeName,
        myCaseTag,
        paymentSystem,
        billSum,
        courtPrice,
        lawyerPrice,
        compensationSystem,
        myUserId
    )

    const handleClick = useCallback(() => {
        if (myCaseTag) {
            addDispute()
            handleClose()
            setDisputeName('')
            setCaseTask('')
            setCaseNumber('')
            setCaseAnnotation('')
            setPaymentSystem('')
            setBillSum(0)
            setCourtPrice(0)
            setLawyerPrice(0)
            setCompensationSystem('')
            setTagtoCase({label: '', value: ''})
        } else {alert('Добавьте Тэг')}
    }, [addDispute, handleClose, myCaseTag])   


  return (
    <div className={classes.root}>
        <div className={classes.content}>
        <div className={classes.title}>
                Споры 
        </div>
                    <MyInput 
                        value={disputeName}
                        type={'text'}
                        handleInput={setDisputeName}
                        inputName={'Название спора'}
                        placeholder={'Введите текст'} 
                    />
                    {/* <MyInput 
                        value={}
                        type={'text'}
                        handleInput={setCaseNumber}
                        inputName={'Номер дела'}
                        placeholder={'Введите номер'} 
                    /> */}
                    <MyInput 
                        value={paymentSystem}
                        type={'text'}
                        handleInput={setPaymentSystem}
                        inputName={'Система оплаты'}
                        placeholder={'Выберете из предложенного'} 
                    />
                    <MyInput 
                        value={billSum}
                        type={'number'}
                        handleInput={setBillSum}
                        inputName={'Сумма выставления счета'}
                        placeholder={'Введите сумму'} 
                    />
                    <MyInput 
                        value={courtPrice}
                        type={'number'}
                        handleInput={setCourtPrice}
                        inputName={'Стоимость судебного заседания'}
                        placeholder={'Введите сумму'} 
                    />
                    <MyInput 
                        value={lawyerPrice}
                        type={'number'}
                        handleInput={setLawyerPrice}
                        inputName={'Стоимость часа работы юриста'}
                        placeholder={'Введите сумму'} 
                    />
                    <MyInput 
                        value={compensationSystem}
                        type={'text'}
                        handleInput={setCompensationSystem}
                        inputName={'Способ компенсации накладных расходов'}
                        placeholder={'Введите текст'} 
                    />
                    Тэг
                    <Select
                    isClearable={false}
                    className={classes.select}
                    classNamePrefix="select"
                    options={selectTagsOptions}
                    onChange={(chioce) => setTagtoCase(chioce)} 
                    defaultValue={{label: '', value: ''}}
                    />
                    <button className={classes.button} onClick={handleClick}>Добавить</button>
        </div>
    </div>
  )
}
