import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase.config';
import { useCallback } from 'react';
import { useAppDispatch } from '../store/reduxHooks';
import { useAuth } from './useAuth';
import { getClients } from '../store/slices/clientSlice';
import { getCases } from '../store/slices/caseSlice';
import { getDisputes } from '../store/slices/disputesSlice';
import { getTasks } from '../store/slices/tasksSlice';

export function useGetClients() {
  const dispatch = useAppDispatch();
  const { isAuth } = useAuth();
  return useCallback(() => {
    isAuth &&
      getDocs(collection(db, 'clients'))
      .then((res) =>
        dispatch(getClients(res.docs.map((el) => ({ ...el.data(), id: el.id })))),
      );
  }, [dispatch, isAuth]);
}

export function useGetCases() {
  const dispatch = useAppDispatch();
  const { isAuth } = useAuth();
  return useCallback(() => {
    isAuth &&
    getDocs(collection(db, 'cases'))
    .then((res) =>
    dispatch(getCases(res.docs.map((el) => ({ ...el.data(), id: el.id }))))
    )
  }, [dispatch, isAuth])
  };

export function useGetDisputs() {
  const dispatch = useAppDispatch();
  const { isAuth } = useAuth();
  return useCallback(() => {
    isAuth &&
    getDocs(collection(db, 'disputes')).then((res) =>
      dispatch(getDisputes(res.docs.map((el) => ({ ...el.data(), id: el.id }))))
    )
  }, [dispatch, isAuth])
};

export function useGetTasks() {
  const dispatch = useAppDispatch();
  const { isAuth } = useAuth();
  return useCallback(() => {
    isAuth &&
    getDocs(collection(db, 'tasks')).then((res) =>
      dispatch(getTasks(res.docs.map((el) => ({ ...el.data(), id: el.id }))))
    )
  }, [dispatch, isAuth])
}