import {FC, useState} from 'react';
import { CaseScreenProps } from './interface';
import classes from './casescreen.module.scss';
import { useAppSelector } from '../../../store/reduxHooks';
import { ButtonBox } from '../../ButtonBox';
import {MdCalendarMonth} from 'react-icons/md';
import { TodoClientItem } from '../../Clientui/TodoClientItem';
import cx from 'classnames';
import {RiCloseFill} from 'react-icons/ri';

import { MoreInformationEditMenu } from '../../../components/MoreInformationEditMenu';
import { AddTodoComponent } from '../../../components/AddTodoComponent';
// import { useHandleUpdateCases } from '../../../hooks/useCase';
import { ChangeCaseForm } from '../ChangeCaseForm';
import { UserCard } from '../../UserUi/UserCard';
import { BsFolderPlus } from 'react-icons/bs';
import { AddDisputeForm } from '../../../components/AddDisputeForm';
import { DisputeItem } from '../../DisputesUi/DisputeItem';

export const CaseScreen: FC<CaseScreenProps> = (props) => {
    const { data} = props;
    const { id, name, clientId, annotation, plan, number, tasks } = data;
    const disputes = useAppSelector(state => state.disputes)
    const filteredDisputes = disputes.filter((disput) => disput.caseId === id)
    const activeDisputes = filteredDisputes.filter((disput) => disput.isActive === true)
    const completedDisputes = filteredDisputes.filter((disput) => disput.isCompleted === true)
    

    const caseUsers = filteredDisputes.reduce<string[]>((acc, arrayItem) => [...acc, ...arrayItem.lawyers], []);
    let filteredCaseUsers: string[] = [];
    caseUsers.forEach((el) => {
    if (!filteredCaseUsers.includes(el)) {   
        filteredCaseUsers.push(el);
    }
    });


    const users = useAppSelector(state => state.users.users);
    
    const magicUser = users.filter((user) => filteredCaseUsers.includes(user.id));

    // const tasks = useAppSelector(state => state.tasks)
    // const filteredTasks = tasks.filter((task) => task.fatherID === id)
    const completedTasks = tasks.filter(i => i.isCompleted === true);
    const unCompletedTasks = tasks.filter(i => i.isCompleted === false);

    const [open, setopen] = useState(true);
    const handleOpenCloseClick = () => setopen(value => !value)



    const cases = useAppSelector((state) => state.cases)

    const [archiveMenuOpen, setArchiveMenuOpen] = useState(true)
    const handleOpenArchiveMenuClick = () => setArchiveMenuOpen(value => !value)

    const [deleteMenuOpen, setDeleteMenuOpen] = useState(true);
    const handleOpenDeleteMenuClick = () => setDeleteMenuOpen(value => !value)

    const [informationMenuOpen, setInformationMenuOpen] = useState(true);
    const handleOpenInfoMenuClick = () => setInformationMenuOpen(value => !value);

    const [addUserOpen, setAddUserOpen] = useState(true);
    const handleAddUserClick = () => setAddUserOpen(value => !value)

    const [changeMenuOpen, setChangeMenuOpen] = useState(true);
    const handleOpenChangeMenuClick = () => setChangeMenuOpen(value => !value)

    const [userOptionChoice, setUserOptionChoice] = useState<any>([] as any)
    const addUserValue = userOptionChoice.value

    
    const [completeCaseMenuOpen, setCompleteCaseMenuOpen] = useState(true)
    const handleCompleteCaseMenuOpenClick = () => setCompleteCaseMenuOpen(value => !value)
    

    const [inputValue, setInputValue] = useState([] as any)
    // const handleCompleteCaseClick = () => {
    //     if(inputValue.length > 50) {
    //         dispatch(completeCase({inputValue, id, clientId}))
    //         setInputValue('')
    //         handleCompleteCaseMenuOpenClick()
    //     } else {alert('Надо бы побольше')}
    // }

    const [disputOpen, setDisputOpen] = useState(true);
    const handleCheck = () => setDisputOpen(value => !value);

    const handleOpenTaskInfoClick = () => {return true}

  return (
    <div className={classes.root}>
         <div className={classes.header}>
            <div className={classes.task}>
                <div className={classes.task}>
                    {}
                </div>
                <div className={classes.description}>

                </div>
            </div>
            <div className={classes.clientRoot}>
                <div className={classes.client}>
                    {name}
                    <div  className={classes.titleforKad}>Номер дела: 
                        <span className={classes.linkToKad} onClick={() => window.open(`https://kad.arbitr.ru/Card?number=${number}`)}>
                         {number}
                        </span>
                    </div>
                </div>
                <ButtonBox 
                handleOpenTaskInfoClick ={handleOpenTaskInfoClick}
                handleCompleteCaseClick={handleCompleteCaseMenuOpenClick}
                handleOpenInfoMenuClick={handleOpenInfoMenuClick}
                handleOpenChangeMenuClick={handleOpenChangeMenuClick}
                handleArchiveCaseClick={handleOpenArchiveMenuClick}
                handleOpenDeleteMenuClick={handleOpenDeleteMenuClick} 
                />
            </div>
        </div>


        <div className={cx(classes.popUp, disputOpen === true && classes.popUpClose)} onClick={handleCheck}>
            <div className={classes.disputeForm} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleCheck}>
                    <RiCloseFill size={22} />
                </button>
                <AddDisputeForm cases={cases} clientId={clientId} caseId = {id} clientName={name} handleClose={handleCheck} />
            </div>
        </div>


        {/* Завершить кейс */}
        <div className={cx(classes.popUp, completeCaseMenuOpen === true && classes.popUpClose)}>
            <div className={classes.completedMenu} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleCompleteCaseMenuOpenClick}>
                    <RiCloseFill size={22} />
                </button>
                <div className={classes.annotationTitle}>
                    Завершить дело
                </div>
                <div className={classes.completeCase}>
                    <textarea wrap='true' className={classes.myArea} value={inputValue} onChange={e => setInputValue(e.target.value)}  name="" id="" placeholder='Введите заключение'></textarea>
                </div>
                <button className={classes.button} >
                    Завершить
                </button>
            </div>
        </div>
        {/* Доп Информация о кейсе */}
        <div className={cx(classes.popUp, informationMenuOpen === true && classes.popUpClose)} >
            <div className={classes.infoMenu} onClick={e => e.stopPropagation()}>
                <MoreInformationEditMenu 
                handleOpenInfoMenuClick={handleOpenInfoMenuClick} 
                annotation={annotation} 
                plan={plan}
                fatherID={id} />
            </div>
        </div>
        {/* Архивировать кейс */}
        <div className={cx(classes.popUp, archiveMenuOpen === true && classes.popUpClose)} onClick={handleOpenArchiveMenuClick}>
            <div className={classes.deleteCaseMenu} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleOpenArchiveMenuClick}>
                    <RiCloseFill size={22} />
                </button>
                Вы уверены что хотите перенести в архив дело "{name}" у клиента {}?
                <div className={classes.deleteButtons}>
                    <button >Да</button> <button onClick={handleOpenArchiveMenuClick}>Нет</button>
                </div>
            </div>
        </div>
        {/* Удалить Кейс */}
        <div className={cx(classes.popUp, deleteMenuOpen === true && classes.popUpClose)} onClick={handleOpenDeleteMenuClick}>
            <div className={classes.deleteCaseMenu} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleOpenDeleteMenuClick}>
                    <RiCloseFill size={22} />
                </button>
                Вы уверены что хотите удалить дело "{name}" у клиента {}?
                <div className={classes.deleteButtons}>
                    <button >Да</button> <button onClick={handleOpenDeleteMenuClick}>Нет</button>
                </div>
            </div>
        </div>
        {/* Изменить Кейс */}
        <div className={cx(classes.popUp, changeMenuOpen === true && classes.popUpClose)}>
            <div className={classes.changeCaseMenu} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleOpenChangeMenuClick}>
                    <RiCloseFill size={22} />
                </button>
                Изменить "{name}"
                <ChangeCaseForm 
                      caseId={id}
                      clientId={clientId}
                      data={data}
                      name={name}
                      handleClose={handleOpenChangeMenuClick}
                />
            </div>
        </div>

        <div className={classes.section}>
            <div className={classes.users}>
                <div className={classes.userBox}>
                    {magicUser.map((user) => (
                        <UserCard data={user} userId={user.id} key={user.id}/>
                    ))}
                    </div>
            </div>
            <div className={classes.calendar}>
                <MdCalendarMonth size={150} />
            </div>
        </div> 
        

        <div className={classes.inputFlex}>
            <div className={classes.inputBlock}>
                <div className={classes.addCase} onClick={handleCheck}>
                    <BsFolderPlus size={30} />
                </div>        
            </div>
            <div className={classes.inputBlock}>
                <div className={classes.inputBox}>
                <AddTodoComponent id={id} />
                </div>     
            </div>        
        </div>

        <div className={cx(classes.popUp, open === true && classes.popUpClose)} onClick={handleOpenCloseClick}>
            <div className={classes.form} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleOpenCloseClick}>
                    <RiCloseFill size={22} />
                </button>
            </div>
        </div>
        <div className={classes.content}>
            <div className={classes.todos}>
                Споры
                {activeDisputes.map((disput) => (
            <DisputeItem disputeId={disput.id} data={disput} key={disput.id} />  
                ))}
                Завершенные споры
                {completedDisputes.map((disput) => (
            <DisputeItem disputeId={disput.id} data={disput} key={disput.id} />  
                ))}
            </div>
            <div className={classes.todos}>
            {unCompletedTasks.length ? (<div>
                    Задачи
                    {unCompletedTasks.map((task) => (
                        <TodoClientItem fatherId={id} data={task} key={task.id} />  
                    ))}
                </div>) : null}

                {completedTasks.length ? (<div>
                    Выполненные задачи
                    {completedTasks.map((task) => (
                        <TodoClientItem fatherId={id} data={task} key={task.id} />  
                    ))}
                </div>) : null}
            </div>
        </div>

    </div>
  )
}
