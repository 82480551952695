import {FC, useState} from "react";
import { ClientsProps } from "./interface";
import classes from './clients.module.scss'
import { useParams } from "react-router";
import { useAppSelector } from "../../store/reduxHooks";
import { ClientItem } from "../../ui/Clientui/ClientItem";
import ClientScreen from "../../ui/Clientui/ClientScreen";
import cx from 'classnames';
import { AddClientForm } from "../../components/AddClientForm";
import {RiCloseFill} from 'react-icons/ri';
import {BsFillPersonPlusFill} from 'react-icons/bs'
import { useFindMyUser } from "../../hooks/useUsers";
import {MdArrowDropDown} from 'react-icons/md'

export const Clients: FC<ClientsProps> = (props) => {
  const {id} = useParams();

  const myUser = useFindMyUser()

  const clients = useAppSelector(state => state.client);
  const activeClients = clients.filter((client) => client.isActive === true)

  const cases = useAppSelector(state => state.cases);
  const disputes = useAppSelector(state => state.disputes);

  const myDisputes = disputes.filter((disput) => disput.lawyers.includes(myUser))
  const myDisputesIds = myDisputes.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.caseId], [])

  const myCases = cases.filter((cas) => myDisputesIds.includes(cas.id))
  const myClientsIds = myCases.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.clientId], [])

  const myClients = activeClients.filter((client) => myClientsIds.includes(client.id))
  const otherClients = activeClients.filter((client) => !myClientsIds.includes(client.id))

  const client = clients.find((client) => client.id === id);
  const [open, setOpen] = useState(true);
  const handleCheck = () => setOpen(value => !value);


  const [openArchiveMenuClick, setOpenArchiveMenuClick] = useState(true)
  const handleOpenArchiveMenuClick =() => setOpenArchiveMenuClick(value => !value)

  const [openDeletedMenuClick, setOpenDeletedMenuClick] = useState(true)
  const handleOpenDeletedMenuClick = () => setOpenDeletedMenuClick(value => !value)  

  const [openClientRoot, setOpenClientRoot] = useState(false)
  const handleOpenClientRootClick = () => setOpenClientRoot(value => !value)

  return (
    <div className={classes.root}>
      <div className={classes.list}>
        <div className={classes.clientList}>
            <div className={classes.title}>Мои Клиенты</div>
            {myClients.map((client) => (
                <ClientItem clientId={client.id} data={client} key={client.id} />
              ))}
              <div className={classes.titleVsButton}>
                <div className={classes.title}>Все Клиенты</div>
                <div onClick={handleOpenClientRootClick} 
                  className={cx(classes.iconRotate, openClientRoot === true && classes.iconRotateTrue )}
                >
                  <MdArrowDropDown size={25} />
                </div>
              </div>
              <div className={cx(classes.clientRoot, openClientRoot === true && classes.clientRootOpen )}>
                {otherClients.map((client) => (
                  <ClientItem clientId={client.id} data={client} key={client.id} />
                ))}              
              </div>

        </div>
        <div>
            <div className={classes.addClient} onClick={handleCheck}>
              <BsFillPersonPlusFill size={35} />
            </div>
            {/* <div className={classes.archiveAndDeletedClient}>
              <div className={classes.archDel} onClick={handleOpenArchiveMenuClick}>
              <BiArchiveIn size={35} />
              </div>
              <div className={classes.archDel} onClick={handleOpenDeletedMenuClick}>
              <BsTrash size={35} />
              </div>
            </div> */}
        </div>
      </div>
      <div className={classes.description}>
          {client ? <ClientScreen data={client} {...client} /> : null}
      </div>

      <div className={cx(classes.popUp, open === true && classes.popUpClose)}>
        <div className={classes.form} onClick={e => e.stopPropagation()}>
          <div className={classes.buttonRoot}>
            <button className={classes.icon} onClick={handleCheck}>
                <RiCloseFill size={22} />
            </button>          
          </div>

          <AddClientForm handleClose={handleCheck} />
        </div>
      </div>

      <div className={cx(classes.popUp, openArchiveMenuClick === true && classes.popUpClose)} onClick={handleOpenArchiveMenuClick}>
        <div className={classes.archDelScreen} onClick={e => e.stopPropagation()}>
        <button className={classes.icon} onClick={handleOpenArchiveMenuClick}>
                <RiCloseFill size={22} />
            </button>
        </div>
      </div>

      <div className={cx(classes.popUp, openDeletedMenuClick === true && classes.popUpClose)} onClick={handleOpenDeletedMenuClick}>
        <div className={classes.archDelScreen} onClick={e => e.stopPropagation()}>
        <button className={classes.icon} onClick={handleOpenDeletedMenuClick}>
                <RiCloseFill size={22} />
          </button>
        </div>
      </div>

    </div>
  )
}
