import {FC, useState} from "react";
import { CalendarProps } from "./interface";
import classes from './calendar.module.scss';
import { Calendar, dayjsLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import dayjs from 'dayjs';
import { myEventsList } from "../../api/eventsArray";
import { useFindMyUser } from "../../hooks/useUsers";
import Select from 'react-select'
import { useAppSelector } from "../../store/reduxHooks";
require('dayjs/locale/ru')
dayjs.locale('ru')
const localizer = dayjsLocalizer(dayjs)



export const Calender: FC<CalendarProps> = (props) => {
  const myUser = useFindMyUser()

  const clients = useAppSelector(state => state.client)
  const clientsOptions = clients.map(item => {
    const client = {
      value: '',
      label: ''
    }
    client.value = item.id
    client.label = item.name
    return client
  })

  const users = useAppSelector(state => state.users.users)
  const usersOptions = users.map(item => {
    const user = {
      value: '',
      label: ''
    }
    user.value = item.id
    user.label = item.label
    return user
  })


  let [customEventList, setCustomEventList] = useState(myEventsList) 
  const myUserEventList = myEventsList.filter((events) => events.lawyers.includes(myUser))
  const [client, setClient] = useState<any>([] as any)
  const [user, setUser] = useState<any>([] as any)

  const myEventsClick = () => {
    setCustomEventList(myUserEventList)
    return customEventList
  }

  const allEventsClick = () => {
    setCustomEventList(myEventsList)
    // setClient([])
    // setUser([])
    return customEventList
  }

  const filterEventListClick = () => {
    if(client.value !== undefined && user.value !== undefined) {
      const filteredEventList = myEventsList.filter(event => event.clientId === client.value && event.lawyers.includes(user.value))
      setCustomEventList(filteredEventList)   
      return customEventList
    } 
    if (client.value !== undefined && user.value === undefined) {
      const filteredEventList = myEventsList.filter(event => event.clientId === client.value)
      setCustomEventList(filteredEventList)   
      return customEventList
    }
    if (client.value === undefined && user.value !== undefined) {
      const filteredEventList = myEventsList.filter(event => event.lawyers.includes(user.value))
      setCustomEventList(filteredEventList)   
      return customEventList
    }
    else {
      const filteredEventList = myEventsList
      setCustomEventList(filteredEventList)
      return customEventList
    }
  }
  
  return (
    <div className={classes.magicCalendar}>
      {/* <CalendarRoot /> */}
      <div className={classes.buttonRoot}>
          <button className={classes.button} onClick={myEventsClick}>
            Мои заседания
          </button>
            <Select
            isClearable={false}
            className={classes.select}
            classNamePrefix="select"
            options={clientsOptions}
            onChange={(chioce) => setClient(chioce)} 
            defaultValue={{label: 'Клиент', value: ''}}
            />
            <Select
            isClearable={false}
            className={classes.select}
            classNamePrefix="select"
            options={usersOptions}
            onChange={(chioce) => setUser(chioce)} 
            defaultValue={{label: 'Сотрудник', value: ''}}
            />
            <button className={classes.button} onClick={filterEventListClick}>
              Выбрать
            </button>
            <button className={classes.button} onClick={allEventsClick}>
              Сброс
          </button>
      </div>
      <Calendar
      localizer={localizer}
      events={customEventList}
      startAccessor="start"
      endAccessor="end"
      style={{ height: 'calc(100vh - 120px)'}}
      views={{
        month: true,
        work_week: true,
        agenda: true,
      }}
      messages={{
        month: 'Месяц',
        work_week: 'Рабочая неделя',
        agenda: 'Cводка',
        date: 'Дата',
        time: 'Время',
        event: 'Событие',
        previous: 'Назад',
        next: 'Вперед',
        today: 'Сегодня',
      }}
    />
    </div>
  )
}
