export const myEventsList = [
    {
      title: 'Апелляция Мошково',
      start: new Date('2023-09-26T14:10:00+07:00'),
      end: new Date('2023-09-26T15:10:00+07:00'),
      department: 'law',
      lawyers: ['FGNTMtdZJuZ5TinR9sJHrf5nPED2',],
      clientId: 'Z24hW3Xo8uarpQsl1HBp'
    },
    { 
      title: 'Камея, Рассвет vs Фукс',
      start: new Date('2023-09-27T12:00:00+07:00'),
      end: new Date('2023-09-27T13:00:00+07:00'),
      department: 'law',
      lawyers: ['XkIjsIdw69ghLudgDDztks3g23S2'],
      clientId: 'eaqD5aSqqlzfeAFCQfFT'
    },
    {
      title: 'Лизунов vs Автотехсервис',
      start: new Date('2023-09-28T12:00:00+07:00'),
      end: new Date('2023-09-28T13:00:00+07:00'),
      department: 'law',
      lawyers: ['FGNTMtdZJuZ5TinR9sJHrf5nPED2'],
      clientId: 'Z24hW3Xo8uarpQsl1HBp'
    },
    {
      title: `Заявление по взысканию судрасходов с ООО «БОВИ»`,
      start: new Date('2023-10-02T09:10:00+07:00'),
      end: new Date('2023-10-02T10:10:00+07:00'),
      department: 'law',
      lawyers: ['XkIjsIdw69ghLudgDDztks3g23S2'],
      clientId: 'Z24hW3Xo8uarpQsl1HBp'
    },
    {
      title: 'Кассация Денвер',
      start: new Date('2023-10-03T10:25:00+07:00'),
      end: new Date('2023-10-03T11:25:00+07:00'),
      department: 'law',
      lawyers: ['FGNTMtdZJuZ5TinR9sJHrf5nPED2'],
      clientId: 'Z24hW3Xo8uarpQsl1HBp'
    },
    {
      title: `Взыскание судрасходов с ООО Азимут (иск Азимут к ООО «МФЦ»)`,
      start: new Date('2023-10-03T10:30:00+07:00'),
      end: new Date('2023-10-03T11:30:00+07:00'),
      department: 'abonent',
      lawyers: ['XkIjsIdw69ghLudgDDztks3g23S2', '7nfPePKd9Zhde2DiUjhHFiEYNT03'],
      clientId: 'Z24hW3Xo8uarpQsl1HBp'
    },
    {
      title: `ДТП Сузун`,
      start: new Date('2023-10-06T14:00:00+07:00'),
      end: new Date('2023-10-06T15:00:00+07:00'),
      department: 'law',
      lawyers: ['XkIjsIdw69ghLudgDDztks3g23S2'],
      clientId: 'eaqD5aSqqlzfeAFCQfFT'
    },
    {
      title: `Верховном суде РФ с/з по банкротству АО КБ Икар`,
      start: new Date('2023-10-09T10:00:00+07:00'),
      end: new Date('2023-10-09T12:00:00+07:00'),
      department: 'law',
      lawyers: ['XkIjsIdw69ghLudgDDztks3g23S2', '1zNyYjM7AKgkkIYvBI3d2tB4kJ43', '7nfPePKd9Zhde2DiUjhHFiEYNT03'],
      clientId: 'eaqD5aSqqlzfeAFCQfFT'
    },
    {
      title: `Включение в ртк ООО СФК «Сибстрой», требования ООО СПА на 150 000`,
      start: new Date('2023-10-10T10:10:00+07:00'),
      end: new Date('2023-10-10T11:10:00+07:00'),
      department: 'law',
      lawyers: ['XkIjsIdw69ghLudgDDztks3g23S2'],
      clientId: 'eaqD5aSqqlzfeAFCQfFT'
    },
    {
      title: `Бродская М.В., банкротство Дерезы, мораторные проценты`,
      start: new Date('2023-10-10T10:20:00+07:00'),
      end: new Date('2023-10-10T11:20:00+07:00'),
      department: 'abonent',
      lawyers: ['XkIjsIdw69ghLudgDDztks3g23S2', '1zNyYjM7AKgkkIYvBI3d2tB4kJ43'],
      clientId: 'eaqD5aSqqlzfeAFCQfFT'
    },
    {
      title: `О включении требований ООО «БОВИ» в ртк ООО «Декарт НСК»`,
      start: new Date('2023-10-12T10:00:00+07:00'),
      end: new Date('2023-10-12T11:00:00+07:00'),
      department: 'law',
      lawyers: ['XkIjsIdw69ghLudgDDztks3g23S2'],
      clientId: 'eaqD5aSqqlzfeAFCQfFT'
    },
    {
      title: `Суд заседание по заявлению Рукаса М.А. о признании недействительной сделки (ООО «БОВИ»)`,
      start: new Date('2023-10-18T14:00:00+07:00'),
      end: new Date('2023-10-18T15:00:00+07:00'),
      department: 'abonent',
      lawyers: ['FGNTMtdZJuZ5TinR9sJHrf5nPED2'],
      clientId: 'eaqD5aSqqlzfeAFCQfFT'
    },
  ]