import {FC} from 'react';
import { CaseItemProps } from './interface';
import classes from './caseitem.module.scss';
import {BsFolder2Open} from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import cx from 'classnames'

export const CaseItem: FC<CaseItemProps> = (props) => {
    const {data, caseId} = props;
    const {name} = data;
    const navigate = useNavigate();
    const handleClick = () => {
      navigate(`/cases/${caseId}`);
    }
    const {id} = useParams()
    
  return (
      <div className={cx(classes.caseItem, caseId === id && classes.caseItemActive)}
      onClick={handleClick}>
        <div className={classes.icon}>
          <BsFolder2Open size={25} />
        </div>
        <div className={classes.caseBody}>
          <div className={classes.title}>
            {name}
          </div>
          <div className={classes.client}>
          </div>
        </div>
      </div>
  )
}