import {FC} from "react";
import { MissionProps } from "./interface";
import classes from './dashboard.module.scss'

export const Mission: FC<MissionProps> = (props) => {
  return (
    <div className={classes.root}>
      <div className={classes.leftbar}>
        поручения
      </div>
      <div className={classes.screen}>

      </div>
    </div>
  )
}
