import  { FC, useState } from 'react';
import { ButtonBoxProps } from './interface';
import classes from './buttonbox.module.scss';
import {BiDotsVerticalRounded, BiArchiveIn} from 'react-icons/bi';
import {BsTrash, BsPencilSquare} from 'react-icons/bs';
import {MdCalendarMonth} from 'react-icons/md';
import {RiInformationLine} from 'react-icons/ri';
import {SlDocs} from 'react-icons/sl';
import {BiTask} from 'react-icons/bi'
import {HiOutlineCheckCircle} from 'react-icons/hi2'
import cx from 'classnames';


export const ButtonBox: FC<ButtonBoxProps> = (props) => {
  const {handleOpenTaskInfoClick, handleCompleteCaseClick, handleOpenDeleteMenuClick, handleOpenInfoMenuClick, handleOpenChangeMenuClick, handleArchiveCaseClick} = props
  const [open, setOpen] = useState(true)
  const openSideBarClick = () => setOpen(value => !value)
  const handleOpenDocumentsMenu = () => alert('Откроем список документов')
  const handleOpenCalendarClick =() => alert('Открываем календарь')
  
  return (
  <div className={classes.buttonBox}>
            <div className={classes.button} title='Завершить дело' onClick={handleOpenTaskInfoClick}>
            <BiTask size={22}/>
        </div>
        <div className={classes.button} title='Завершить дело' onClick={handleCompleteCaseClick}>
            <HiOutlineCheckCircle size={22}/>
        </div>
        <div className={classes.button} title='Информация' onClick={handleOpenInfoMenuClick}>
            <RiInformationLine size={22}/>
        </div>
        <div className={classes.button} >
          <SlDocs size={22} title='Документы' onClick={handleOpenDocumentsMenu}/>
        </div>
        <div className={classes.button}>
          <MdCalendarMonth size={22} title='Календарь' onClick={handleOpenCalendarClick}/>
        </div>
        <div className={classes.button}>
          <BiDotsVerticalRounded onClick={openSideBarClick} size={22} title='Увидеть больше'/>               
        </div>
        <div className={cx(classes.popUp, open === true && classes.popUpClose)} onClick={e => e.stopPropagation()}>
            <div className={classes.sideBarButton}>
              <BsPencilSquare size={22} title='Редактировать' onClick={handleOpenChangeMenuClick}/>
            </div>
            <div className={classes.sideBarButton} title='В Архив' onClick={handleArchiveCaseClick}>
              <BiArchiveIn size={22}/>
            </div>
            <div className={classes.sideBarButton} title='Удалить' onClick={handleOpenDeleteMenuClick}>
            <BsTrash size={22}/>
            </div>
            
        </div>
  </div>
  )
}
