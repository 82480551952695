import React, {FC} from 'react';
import { ChatItemProps } from './interface';
import classes from './chatitem.module.scss';
import { useNavigate } from 'react-router';

export const ChatItem: FC<ChatItemProps> = (props) => {
    const {data} = props;
    const {id, title} = data;
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(`/chats/${id}`);
    }
  return (
    <div className={classes.root}
    onClick={handleClick}>
        <div className={classes.avatar}></div>
        {title}
    </div>
  )
}
