import {FC} from 'react';
import { UserScreenProps } from './interface';
import classes from './userscreen.module.scss';

import { useAppSelector } from "../../../store/reduxHooks";
import cx from 'classnames'
import { DisputeItem } from '../../DisputesUi/DisputeItem';
import { TodoClientItem } from '../../Clientui/TodoClientItem';

export const UserScreen: FC<UserScreenProps> = (props) => {
    const {data} = props;
    const {name, surName, fatherName, specs, id} = data;
    const clients = useAppSelector(state => state.client);
    const cases = useAppSelector(state => state.cases);
    const disputes = useAppSelector(state => state.disputes);
    const activeDisputes = disputes.filter((disput) => disput.isActive === true)
    const myDisputes = activeDisputes.filter((disput) => disput.lawyers.includes(id))
    const myDisputesIds = myDisputes.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.caseId], [])
    const myDiputesIdsForTasks = myDisputes.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.id], [])
  
    const myCases = cases.filter((cas) => myDisputesIds.includes(cas.id))
    const myClientsIds = myCases.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.clientId], [])
  
    const myClients = clients.filter((client) => myClientsIds.includes(client.id))
  
    // const tasks = useAppSelector(state => state.tasks)
    // const myTasks = tasks.filter((task) => myDiputesIdsForTasks.includes(task.fatherID))
  
    // const unCompletedTasks = myTasks.filter(i => i.isCompleted === false);
  
    
  return (
    <div className={classes.root}>
        <div className={classes.header}>
            <div className={classes.avatar}></div>
            <div>
                <div className={classes.name}>{surName} {name} {fatherName}</div>
                <div className={cx(classes.specsSud, 
                        specs === 'IT-отдел' && classes.specsIt,
                        specs === 'CEO' && classes.specsCeo,
                        specs === 'Абонентский отдел' && classes.specsAbonent,
                        specs === 'Оценщик' && classes.specsOcenka,
                        specs === 'Кадастровый отдел' && classes.specsKadastr)}>{specs}</div>

            </div>        
        </div>
        <div className={classes.content}>
            <div className={classes.cases}>
              <div className={classes.title}>Споры</div>       
              {myDisputes.map((cas) => (
                    <DisputeItem disputeId={cas.id} data={cas} key={cas.id} />  
                ))}  
            </div>
            <div className={classes.todos}>
                <div className={classes.title}>Задачи</div>       
                {/* {unCompletedTasks.map((todo) => (
                    <TodoClientItem data={todo} key={todo.id} />
                ))} */}
            </div>
        </div>
    </div>
  )
}
