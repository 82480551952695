import {FC} from 'react';
import { CaseClientRootProps } from './interface';
import classes from './casesclientroor.module.scss'
import { CaseItem } from '../CaseItem';
import {RiInformationLine} from 'react-icons/ri';
import { useNavigate, useParams } from 'react-router-dom';
import cx from 'classnames'
import { useAppSelector } from '../../../store/reduxHooks';

export const CaseClientRoot: FC<CaseClientRootProps> = (props) => {
  const {data, clientId} = props
  const {name} = data
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/clients/${clientId}`);
  }
  const {id} = useParams()
  const cases = useAppSelector(state => state.cases);
  const myCases = cases.filter((cas) => cas.clientId === clientId)
  const activeCases = myCases.filter((cas) => cas.isActive === true);
  const foundedCase = activeCases.find((cas) => cas.id === id)


  return (
    <div className={cx(classes.root, foundedCase  && classes.selectedRoot, !activeCases.length && classes.emptyRoot)}>
        <div className={classes.title}>
            {name}
            <div className={cx(classes.icon, foundedCase  && classes.selectedIcon)}>
            <RiInformationLine onClick={handleClick} size={25} />
            </div>
        </div>
        <div className={classes.list}>
        {myCases.map((cas) => (
            <CaseItem caseId={cas.id} data={cas} key={cas.id} />  
        ))}
        </div>

    </div>
  )
}
