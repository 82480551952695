import {FC} from "react";
import { ChatsProps } from "./interface";
import { ChatBar } from "../../ui/ChatUi/ChatBar";
import { ChatScreen } from "../../ui/ChatUi/ChatScreen";
import classes from './chats.module.scss'
import { useParams } from "react-router";
import { useAppSelector } from "../../store/reduxHooks";

export const Chats: FC<ChatsProps> = (props) => {
  const {id} = useParams();
  const chats = useAppSelector(state => state.chats);
  const chat = chats.find((chat) => chat.id === id);

  return (
    <div className={classes.root}>
      <ChatBar />
      
        {chat ?<ChatScreen data={chat} key={chat.id} /> : null}
      
    </div>
  )
}
