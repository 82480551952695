import {FC} from 'react';
import { UserCardProps } from './interface';
import classes from './usercard.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../store/reduxHooks';
import cx from 'classnames'

export const UserCard: FC<UserCardProps> = (props,) => {
    const {data, userId} = props;
    const { name, surName, fatherName, specs} = data;
    const {id} = useParams()
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(`/users/${userId}`);
    }
    const disputes = useAppSelector(state => state.disputes)
    const filteredDisputes = disputes.filter((disput) => disput.lawyers.includes(userId))
  return (
    <div className={cx(classes.root, userId === id && classes.activeRoot)}
    onClick={handleClick}>
        <div className={classes.avatar}></div>
        <div className={classes.content}>
            <div className={classes.text}>
                <div className={classes.user}>
                        {surName} {name} {fatherName} 
                </div>
                    <div className={cx(classes.specsSud, 
                        specs === 'IT-отдел' && classes.specsIt,
                        specs === 'CEO' && classes.specsCeo,
                        specs === 'Абонентский отдел' && classes.specsAbonent,
                        specs === 'Оценщик' && classes.specsOcenka,
                        specs === 'Кадастровый отдел' && classes.specsKadastr)}>{specs}</div>
                    <div className={classes.case}>дел в работе: {filteredDisputes.length}</div>
            </div>
        </div>
    </div>
  )
}
