import { useCallback } from "react";
import { useAppDispatch } from "../store/reduxHooks"
import { addMail } from "../store/slices/chatSlice";
import { doc, updateDoc } from "firebase/firestore";
import { Message } from "../api/interface";
import { db } from "../firebase.config";

export function useChatAdd(text: string, id: string, myId: string) {
    const dispatch = useAppDispatch();
    return () => {
        if (text.trim().length) {
            dispatch(addMail({text, id, myId}))
          } 
    }
}

export function useHandleUpdateChats(chatId: string | undefined, messages: Message[] | undefined) {
    return useCallback(() => {
        if (messages !== undefined) {
            updateDoc(doc(db, 'chats', `${chatId}`), {messages: messages})
        }
    }, [chatId, messages])
}
