import React, { FC } from 'react'
import { BillItemProps } from './interface'
import classes from './billitem.module.scss'
import cx from 'classnames'
import { RiBillFill } from 'react-icons/ri'
import {TbPigMoney, TbUserCircle} from 'react-icons/tb'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { useAppSelector } from '../../../store/reduxHooks'

export const BillItem:FC <BillItemProps> = (props) => {
    const {id, teg, totalHours, totalSumm, clientId} = props;
    const clients = useAppSelector(state => state.client);
    const myClient = clients.find((item) => item.id === clientId)
  return (
    <div className={classes.root}>
        <div className={classes.billHeader}>
            <div className={cx(classes.tagMark, 
                teg === 'Взыскание задолженности' && classes.tagMarkDolgi,
                teg === 'Деликты' && classes.tagMarkDelicti,
                teg === 'Банкротство' && classes.tagMarkBancrot,
                teg === 'Недвижимость' && classes.tagMarkRealEstate,
                teg === 'Споры с гос. и имущ. организациями' && classes.tagMarkGosSpori,
                teg === 'Корпоративные споры' && classes.tagMarkKorporati,
                teg === 'Налоговые споры' && classes.tagMarkNalogi,
                teg === 'Интеллектуальная собственность' && classes.tagMarkIntellect,
            )}>
                <RiBillFill size={30} />
            </div>
            <div className={classes.billBody}>
                <div>
                    Счет № {id}
                </div>
                <div>
                    <div className={classes.info}>
                        <TbPigMoney size={25} />
                        <div className={classes.billInfo}>{totalSumm} руб</div>
                    </div>
                    <div className={classes.info}>
                    <AiOutlineClockCircle size={25} />
                        <div className={classes.billInfo}>{totalHours} часов</div>
                    </div>

                </div>
            </div>
        </div>    
        <div className={classes.billFooter}>      
            <div className={classes.infoFooter}>
                <TbUserCircle size={25} />
                <div className={classes.billInfo}>{myClient?.name}</div>
            </div> 
        </div>
    </div>
  )
}