import { FC, useState } from 'react'
import { AddTodoComponentProps } from './interface'
import classes from './addtodocomponent.module.scss'
import { BsPlusCircleFill } from 'react-icons/bs'
import { RiCloseFill } from 'react-icons/ri'
import cx from 'classnames'
import { useFindMyUser } from '../../hooks/useUsers'
import { useHandleAddTasks } from '../../hooks/useCase'
import dayjs from 'dayjs';

export const AddTodoComponent: FC<AddTodoComponentProps> = (props) => {
    const {id} = props;
    const [todo, setTodo] = useState<string>([] as any);

    

    const myUser = useFindMyUser()
    // const creator = `${myUser?.name} ${myUser?.surName}`
    const formattedDate = dayjs().valueOf()


    const [open, setOpen] = useState(true);
    const handleOpenAddTodoMenuClick = () => setOpen(value => !value);

    const handleKeyPress = (e: any) => {
        if(e.key === 'Enter'){
          handleClick()
        }
    }

    const addTask = useHandleAddTasks(id, todo, formattedDate, myUser)
    const handleClick = () => {
        if (todo.length) {
            addTask()
            setTodo('')
            handleOpenAddTodoMenuClick()
        }
        else {alert('Заполните поле')}
    }

  return (
    <div className={classes.root} onClick={handleOpenAddTodoMenuClick}>
        <BsPlusCircleFill size={25} />
        <div className={cx(classes.popUp, open === true && classes.popUpClose)} onClick={handleOpenAddTodoMenuClick}>
                <div className={classes.deleteCaseMenu} onClick={e => e.stopPropagation()}>
                    <button className={classes.closeIcon} onClick={handleOpenAddTodoMenuClick}>
                        <RiCloseFill size={22} />
                    </button>
                    <div className={classes.inputBox}>
                        <input
                        value={todo}
                        className={classes.input}
                        placeholder='Добавить задачу'
                        onChange={(e) => setTodo(e.target.value)}
                        onKeyDown={handleKeyPress}
                        />
                        <button className={classes.button} onClick={handleClick}>Отправить</button>
                    </div>
                </div>
             </div>
    </div>
  )
}
