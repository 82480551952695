import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../index';
import { Todo } from '../../api/interface';

interface TodoState {
    todos: Todo[]
}

const initialState: TodoState = {
    todos: [
        {
            id: '21212',
            title: 'Съездить на почту',  
        },
        {
            id: '212112',
            title: 'Оплатить госпошлину',  
        },
        {
            id: '2121ed2',
            title: 'Написать исковое',  
        },
        {
            id: '2121412',
            title: 'Сдать авансовый отчет',  
        },
        {
            id: '21212312',
            title: 'Сделать красиво',  
        },
        
    ]
}

export const todoSlice = createSlice({
    name: 'todos',
    initialState,
    reducers:{}
})

export default todoSlice.reducer