import {FC, useState} from 'react';
import { DisputesProps } from "./interface";
import classes from './disputes.module.scss';
import { useAppSelector } from "../../store/reduxHooks";
import { useNavigate, useParams } from 'react-router-dom';
import { DisputeScreen } from "../../ui/DisputesUi/DisputeScreen";
import { BsTrash} from 'react-icons/bs';
import {BiArchiveIn} from 'react-icons/bi';
import { DisputeCaseRoot } from '../../ui/DisputesUi/DiputesCaseRoot';
import { useFindMyUser } from "../../hooks/useUsers";
import { MdArrowDropDown } from 'react-icons/md';
import cx from 'classnames';


export const Disputes: FC<DisputesProps> = (props) => {
  const { id } = useParams();
  const navigate = useNavigate()

  const myUser = useFindMyUser()

  const cases = useAppSelector(state => state.cases);
  const disputes = useAppSelector(state => state.disputes);
  
  const filteredDisputes = disputes.filter((disput) => disput.isActive === true)
  const myDisputes = filteredDisputes.filter((disput) => disput.lawyers.includes(myUser))
  const myDisputesIds = myDisputes.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.caseId], [])
  const myCases = cases.filter((cas) => myDisputesIds.includes(cas.id))
  const otherCases = cases.filter((cas) => !myDisputesIds.includes(cas.id))
  const handleGotoArchiveClick = () => {
    navigate('/archivedcases');
  }
  const handleGotoDeletedClick = () => {
    navigate('/deletedcases');
  }

  const oneDispute = disputes.find((cas) => cas.id === id);  

  const [openClientRoot, setOpenClientRoot] = useState(false)
  const handleOpenClientRootClick = () => setOpenClientRoot(value => !value)
  return (
    <div className={classes.root}>
      <div className={classes.navigationPanel}>

          <div className={classes.list}>
            <div className={classes.title}>Мои споры</div>
            {myCases.map((cas) => (
                <DisputeCaseRoot caseId={cas.id} data={cas} key={cas.id} />
              ))}
              <div className={classes.titleVsButton}>
                <div className={classes.title}>Все споры</div>
                <div onClick={handleOpenClientRootClick} 
                  className={cx(classes.iconRotate, openClientRoot === true && classes.iconRotateTrue )}
                >
                  <MdArrowDropDown size={25} />
                </div>
              </div>
              <div className={cx(classes.clientRoot, openClientRoot === true && classes.clientRootOpen )}>
                {otherCases.map((cas) => (
                  <DisputeCaseRoot caseId={cas.id} data={cas} key={cas.id} />
                ))}              
              </div>
        </div>
          <div className={classes.archiveAndDeletedClient}>
                <div className={classes.archDel} title="Архивные дела" onClick={handleGotoArchiveClick}>
                <BiArchiveIn size={35} />
                </div>
                <div className={classes.archDel} title="Удаленные дела" onClick={handleGotoDeletedClick}>
                <BsTrash size={35} />
                </div>
              </div>
      </div>
        
        <div className={classes.description}>
        {oneDispute ? <DisputeScreen data={oneDispute} {...oneDispute} /> : null}
        </div>

    </div>

  )
}
