import {FC} from 'react';
import { LeftNavBarProps } from './interface';
import {useNavigate} from 'react-router-dom'
import {
    MdOutlineDesktopWindows,
    MdOutlineCases,
    MdOutlineAssignmentLate,
    MdCalendarMonth
    } from 'react-icons/md';
import {BsPeopleFill} from 'react-icons/bs'
import {HiUsers} from 'react-icons/hi2';
import {FaMoneyBillWaveAlt} from 'react-icons/fa'
import classes from "./leftnavbar.module.scss";
import {GoLaw} from 'react-icons/go'
import cx from 'classnames'


export const LeftNavBar: FC<LeftNavBarProps> = (props) => {
    const navigate = useNavigate();

    const currentPath = window.location.pathname;  

  return (
    <div className={classes.root}>
        <div className={cx(classes.menuItem, currentPath === '/' && classes.activeMenuItem)}
        onClick={() => navigate('/')}>
            <MdOutlineDesktopWindows className={classes.icon} size={30}/>
            <div className={classes.text}>Доска</div>
        </div>
        <div className={cx(classes.menuItem, currentPath === '/tasks' && classes.activeMenuItem)}
        onClick={() => navigate('/tasks')}>
            <MdOutlineAssignmentLate className={classes.icon} size={30}/>
            <div className={classes.text}>Поручения</div>

        </div>
        <div className={classes.menuItem}
        onClick={() => navigate('/calendar')}>
            <MdCalendarMonth className={classes.icon} size={30}/>
            <div className={classes.text}>Календарь</div>
        </div>
        <div className={cx(classes.menuItem, currentPath === '/clients' && classes.activeMenuItem)}
        onClick={() => navigate('/clients')}>
            <BsPeopleFill className={classes.icon} size={30}/>
            <div className={classes.text}>Клиенты</div>
        </div>
        <div className={cx(classes.menuItem, currentPath === '/cases' && classes.activeMenuItem)}
        onClick={() => navigate('/cases')}>
            <MdOutlineCases className={classes.icon} size={30}/>
            <div className={classes.text}>Дела</div>
        </div>
        <div className={cx(classes.menuItem, currentPath === '/disputes' && classes.activeMenuItem)}
        onClick={() => navigate('/disputes')}>
            <GoLaw className={classes.icon} size={30}/>
            <div className={classes.text}>Споры</div>
        </div>
        <div className={cx(classes.menuItem, currentPath === '/bills' && classes.activeMenuItem)}
        onClick={() => navigate('/bills')}>
            <FaMoneyBillWaveAlt className={classes.icon} size={30}/>
            <div className={classes.text}>Счета</div>
        </div>
        <div className={cx(classes.menuItem, currentPath === '/users' && classes.activeMenuItem)}
        onClick={() => navigate('/users')}>
            <HiUsers className={classes.icon} size={30}/>
            <div className={classes.text}>Коллеги</div>
        </div>
        {/* <div className={classes.menuItem}
        onClick={() => navigate('/chats')}>
            <HiOutlineChatBubbleLeftRight className={classes.icon} size={30}/>
            <div className={classes.text}>Чат</div>
        </div> */}
        {/* <div className={classes.menuItem}
        onClick={() => navigate('/help')}>
            <MdHelp className={classes.icon} size={30}/>
            <div className={classes.text}>Помощь</div>
        </div> */}
    </div>
  )
}
