import {FC, useState} from 'react';
import { ClientScreenProps } from './interface';
import classes from './clientscreen.module.scss';
import {FiPhone} from 'react-icons/fi'
import { CaseItem } from '../../CaseUi/CaseItem';
import {MdCalendarMonth} from 'react-icons/md';
import { ContactCard } from '../../ContactCard';
import {RiCloseFill} from 'react-icons/ri';
import cx from 'classnames';
import { AddCaseForm } from '../../../components/AddCaseForm';
import {BsPlusCircleFill, BsFolderPlus} from 'react-icons/bs'
import AddContactForm from '../AddContactForm';
import { ButtonClientBox } from '../ButtonClientBox';
import { ChangeClientForm } from '../ChangeClientForm';
import { useHandleArchiveClients, useHandleDeleteCLients } from '../../../hooks/useClients';
import { useNavigate } from 'react-router';
// import { useHandleUpdateCases } from '../../../hooks/useCase';
import { useAppSelector } from '../../../store/reduxHooks';
import { AddTodoComponent } from '../../../components/AddTodoComponent';
import { TodoClientItem } from '../TodoClientItem';

const ClientScreen: FC<ClientScreenProps> = (props) => {
    const {data} = props;
    const {name, phone, inn, ogrn, kpp, contacts, id, tasks} = data;
    const clientId = id;
    const cases = useAppSelector(state => state.cases);
    const myCases = cases.filter((cas) => cas.clientId === clientId)
    const activeCases = myCases.filter((cas) => cas.isActive === true)

    // const tasks = useAppSelector(state => state.tasks)
    // const myTasks = tasks.filter((task) => task.fatherID === id)
    const completedTasks = tasks.filter(i => i.isCompleted === true);
    const uncompletedTasks = tasks.filter(i => i.isCompleted === false);


    const navigate = useNavigate()

    const [open, setOpen] = useState(true);
    const handleCheck = () => setOpen(value => !value);

    const [contactsOpen, setContactsOpen] = useState(true);
    const handleContactClick = () => setContactsOpen(value => !value)

    const [openClientArchiveMenu, setOpenClientArchiveMenu] = useState(true)
    const handleOpenArchiveMenuClick = () => setOpenClientArchiveMenu(value => !value)

    const archivedClient = useHandleArchiveClients(id)
    const handleArchiveClientClick = () => {
        archivedClient()        
        handleOpenArchiveMenuClick()
        navigate('/clients')
    }

    const [openClientDeleteMenu, setOpenClientDeleteMenu] = useState(true)
    const handleOpenClientDeleteMenuClick = () => setOpenClientDeleteMenu(value => !value)
    
    const deleteClient = useHandleDeleteCLients(id)
    const handleDeleteClientClick = () => {
        deleteClient()
        handleOpenClientDeleteMenuClick()
        navigate('/clients')
    }

    const [openClientChangeMenu, setOpenClientChangeMenu] = useState(true)
    const handleOpenChangeClientMenuClick = () => setOpenClientChangeMenu(value => !value)

  return (
    <div className={classes.root}>
        <div className={cx(classes.popUp, open === true && classes.popUpClose)} onClick={handleCheck}>
            <div className={classes.form} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleCheck}>
                    <RiCloseFill size={22} />
                </button>
                <AddCaseForm cases={cases} clientId={clientId} clientName={name} handleClose={handleCheck} />
            </div>
        </div>

        <div className={cx(classes.popUp, openClientArchiveMenu === true && classes.popUpClose)} onClick={handleOpenArchiveMenuClick}>
            <div className={classes.clientInfoMenu} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleOpenArchiveMenuClick}>
                    <RiCloseFill size={22} />
                </button>
                    Вы уверены что хотите перенести в архив клиента {name}?
                <div className={classes.deleteButtons}>
                    <button onClick={handleArchiveClientClick}>Да</button> <button onClick={handleOpenArchiveMenuClick}>Нет</button>
                </div>
                
            </div>
        </div>

        <div className={cx(classes.popUp, openClientDeleteMenu === true && classes.popUpClose)} onClick={handleOpenClientDeleteMenuClick}>
            <div className={classes.clientInfoMenu} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleOpenClientDeleteMenuClick}>
                    <RiCloseFill size={22} />
                </button>
                    Вы уверены что хотите удалить клиента {name}?
                <div className={classes.deleteButtons}>
                    <button onClick={handleDeleteClientClick}>Да</button> <button onClick={handleOpenClientDeleteMenuClick}>Нет</button>
                </div>
                
            </div>
        </div>

        <div className={cx(classes.popUp, openClientChangeMenu === true && classes.popUpClose)} onClick={handleOpenChangeClientMenuClick}>
            <div className={classes.changeClientForm} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleOpenChangeClientMenuClick}>
                    <RiCloseFill size={22} />
                </button>
                <ChangeClientForm data={data} id={id} handleClose={handleOpenChangeClientMenuClick} />
            </div>
        </div>

      <div className={cx(classes.popUp, contactsOpen === true && classes.popUpClose)} onClick={handleContactClick}>
        <div className={classes.contactForm} onClick={e => e.stopPropagation()}>
        <button className={classes.closeIcon} onClick={handleContactClick}>
                <RiCloseFill size={22} />
            </button>
           <AddContactForm clientId={id} handleClose={handleContactClick} />
        </div>
      </div>

        <div className={classes.header}>
            <div className={classes.data}>
                Реквизиты:
                <div className={classes.dataNumbers}>
                    ИНН: {inn} <br />
                    ОГРН/КПП: {ogrn}/{kpp}
                </div>
            </div>
            <div className={classes.client}>
                <div>
                    {name}
                </div>
                <div className={classes.phone}>
                    <FiPhone className={classes.icon} size={20} />
                    {phone}
                </div>
                <ButtonClientBox 
                handleOpenArchiveMenuClick={handleOpenArchiveMenuClick}
                handleOpenClientDeleteMenuClick={handleOpenClientDeleteMenuClick}
                handleOpenChangeClientMenuClick={handleOpenChangeClientMenuClick}
                />
            </div>
        </div>
        <div className={classes.information}>
            <div className={classes.contacts}>
                Контакты
                <div className={classes.contactsCard}>
                    {contacts.length > 0 ? (
                        <div className={classes.contactsArray}>
                            {contacts.map((contact) => (<ContactCard constactId={contact.id} data={contact} key={contact.id} />))}
                        </div>) 
                    : null}

                    <div className={classes.addContact}>
                        <BsPlusCircleFill size={40} onClick={handleContactClick} />
                    </div>

                </div>
            </div>
            <div className={classes.calendar}>
             <MdCalendarMonth size={150} />
            </div>
        </div>
        <div className={classes.buttons}>
        <div className={classes.inputBox}>
        <div className={classes.addCase} onClick={handleCheck}>
                <BsFolderPlus size={30} />
            </div>            </div>

            <div className={classes.inputBox}>
                    <AddTodoComponent id={id} />
            </div>
        </div>
        <div className={classes.rootContent}>
            
        <div className={classes.content}>
            <div className={classes.cases}>

                    Дела
                    <div className={classes.caseList}>
                        {activeCases.map((cas) => (<CaseItem caseId={cas.id} data={cas} key={cas.id} />))}
                    </div>        
                </div>
                <div className={classes.todos}> 
                    {uncompletedTasks.length ? (<div>
                        <div className={classes.title}>
                        Задачи
                        </div>
                        {uncompletedTasks.map((task) => (<TodoClientItem fatherId={id}  data={task} key={task.id} />))}
                    </div>) : null}

                    {completedTasks.length ? (<div>
                        <div className={classes.title}>
                        Comleted tasks
                        </div>
                        {completedTasks.map((task) => (<TodoClientItem fatherId={id} data={task} key={task.id} />))}
                    </div>) : null}
                    
                </div>
            </div>
            
        </div>
    </div>
  )
}

export default ClientScreen