import  { FC } from 'react'
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { LoginProps } from './interface'
import { Form } from '../Form'
import { useAppDispatch } from '../../../store/reduxHooks'
import { setUser } from '../../../store/slices/userAuthSlice';
import { useNavigate } from 'react-router';
import classes from './login.module.scss'

export const Login:FC <LoginProps> = (props) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate()

    const handleLoginClick = (email: string, password: string) => {
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
            .then(({user}) => {
              dispatch(setUser({
                email: user.email,
                id: user.uid
              }));
              navigate('/')
            })
            .catch(console.error)
    }
  return (
    <div className={classes.root}>
      <div className={classes.form}>
        <Form title='Войти' handleClick={handleLoginClick} />
      </div>
    </div>
  )
}
