import {FC} from 'react';
import { ClientItemProps } from './interface';
import classes from './clientitem.module.scss'
import { useNavigate, useParams } from 'react-router';
import {BsPersonFill} from 'react-icons/bs';
import {HiOutlineOfficeBuilding} from 'react-icons/hi'
import {MdWorkOutline} from 'react-icons/md'
import cx from 'classnames'

export const ClientItem: FC<ClientItemProps> = (props) => {
    const {data, clientId} = props;
    const { name, clientType } = data;
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(`/clients/${clientId}`)
    }
    const {id} = useParams()

  return (
    <div className={cx(classes.clientItem, clientId === id && classes.clientItemActive)}
    onClick={handleClick}>
        {clientType === 'Физ. лицо' ? <BsPersonFill size={22} /> : null}
        {clientType === 'Юр. лицо' ? <HiOutlineOfficeBuilding size={22} /> : null}
        {clientType === 'ИП' ? <MdWorkOutline size={22} /> : null}
      <span className={classes.title}>
       {name}
      </span>
    </div>
  )
}
