import {FC} from 'react';
import { SmallCalendarProps } from './interface';
import classes from './smallcalendar.module.scss';
import {BsFillBookmarkFill} from 'react-icons/bs';
import {MdLocalPostOffice, MdTrain} from 'react-icons/md';
import {GiScales} from 'react-icons/gi'



export const SmallCalendar: FC<SmallCalendarProps> = (props) => {
  return (
    <div className={classes.root}>
        <div className={classes.box}>
            9:00-14:00
            <div className={classes.timeBox}>
                <div className={classes.time}>
                9:00
                </div>
                <BsFillBookmarkFill className={classes.icon} size={18} color='#dc2626' />
            </div>
            <div className={classes.timeBox}>
            <div className={classes.time}>
                10:00
                </div>       
                <MdLocalPostOffice className={classes.icon} size={18} color='#2563eb' />
     
            </div>
            <div className={classes.timeBox}>
            <div className={classes.time}>
                11:00
                </div> 
                <BsFillBookmarkFill className={classes.icon} size={18} color='#65a30d' />
                <GiScales className={classes.icon} size={18} color='#dc2626' />
            </div>
            <div className={classes.timeBox}>
            <div className={classes.time}>
                12:00
                </div>        
                <MdLocalPostOffice className={classes.icon} size={18} color='#c026d3' />
            </div>
            <div className={classes.timeBox}>
            <div className={classes.time}>
                13:00
                </div>
                <BsFillBookmarkFill className={classes.icon} size={18} color='#c026d3' />
                <BsFillBookmarkFill className={classes.icon} size={18} color='#dc2626' />
            </div>
            
        </div>
        <div className={classes.box}>
            14:00-18:00
            <div className={classes.timeBox}>
            <div className={classes.time}>
                14:00
                </div>            </div>
            <div className={classes.timeBox}>
            <div className={classes.time}>
                15:00
                </div>
                <BsFillBookmarkFill className={classes.icon} size={18} color='#2563eb' />
                <BsFillBookmarkFill className={classes.icon} size={18} color='#65a30d' />


            </div>
            <div className={classes.timeBox}>
            <div className={classes.time}>
                16:00
                </div>            </div>
            <div className={classes.timeBox}>
            <div className={classes.time}>
                17:00
                </div>
                <BsFillBookmarkFill className={classes.icon} size={18} color='#65a30d' />   
                <MdTrain className={classes.icon} size={18} color='#c026d3' />
                <BsFillBookmarkFill className={classes.icon} size={18} color='#dc2626' />

         
                </div>
            <div className={classes.timeBox}>
            <div className={classes.time}>
                18:00
                </div>            </div>
        </div>


    </div>
  )
}