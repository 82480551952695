import { Routes, Route} from 'react-router';
import {useEffect} from "react";
import './App.css';
import { LeftNavBar } from './components/LeftNavBar';
import { BrowserRouter } from 'react-router-dom';
import { MyDashboard } from './Pages/MyDashboard';
import { Mission } from './Pages/Mission';
import { Calender } from './Pages/Calendar';
import { Cases } from './Pages/Cases';
import { NavBar } from './components/NavBar';
import { Bills } from './Pages/Bills';
import { Users } from './Pages/Users';
import { Chats } from './Pages/Chats';
import { Help } from './Pages/Help';
import { UserProfile } from './Pages/UserProfile';
import { Clients } from './Pages/Clients';
import { ArchivedCases } from './Pages/ArchivedCases';
import { DeletedCases } from './Pages/DeletedCases';
import { Login } from './components/AuthForm/Login';
import { useAuth } from './hooks/useAuth';
import { useGetCases, useGetClients, useGetDisputs, useGetTasks } from './hooks/useGetDocs';
import { Disputes } from './Pages/Disputes';
import dayjs from 'dayjs';

function App() {
  const {isAuth} = useAuth()
  const getMyDocs = useGetClients();
  const getMyCases = useGetCases()
  const getMyDisputs = useGetDisputs()
  const getMyTasks = useGetTasks()


  const myTime = dayjs()
  const formattedDate = myTime.format('HH:mm.ss DD.MM');

  const interval = setInterval(() => {
    if (isAuth) {
      getMyDocs()
      getMyCases()
      getMyDisputs()
      console.log(`update main page at ${formattedDate}`)
    }
  }, 600000)

  useEffect(() => {isAuth && 
    getMyDocs(); 
    getMyCases(); 
    getMyDisputs(); 
    // getMyTasks()
    console.log(`update at`) }, [getMyCases, getMyDisputs, getMyDocs, isAuth])


  return (
    <BrowserRouter>
    {isAuth ? ( 
      <div className='App'>
        <LeftNavBar />
        <div className='main'>
          <NavBar />
          <Routes>
            <Route path='/' element={<MyDashboard />}/>
            <Route path='/tasks' element={<Mission />}/>
            <Route path='/calendar' element={<Calender />}/>
            <Route path='/cases' element={<Cases />}/>
            <Route path='/cases/:id' element={<Cases />}/>
            <Route path='/archivedcases' element={<ArchivedCases />}/>
            <Route path='/archivedcases/:id' element={<ArchivedCases />}/>
            <Route path='/deletedcases' element={<DeletedCases />}/>
            <Route path='/deletedcases/:id' element={<DeletedCases/>}/>
            <Route path='/bills' element={<Bills />}/>
            <Route path='/users' element={<Users />}/>
            <Route path='/users/:id' element={<Users />}/>
            <Route path='/chats' element={<Chats />}/>
            <Route path='/chats/:id' element={<Chats />}/>
            <Route path='/help' element={<Help />}/>
            <Route path='/userprofile' element={<UserProfile />}/>
            <Route path='/clients' element={<Clients />}/>
            <Route path='/clients/:id' element={<Clients />}/>
            <Route path='/disputes' element={<Disputes />}/>
            <Route path='/disputes/:id' element={<Disputes />}/>
          </Routes>
        </div>
    </div>
    ) : (
      <div>
      <Login />
    </div>
  )}
    </BrowserRouter>
    );
}

export default App;
