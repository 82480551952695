import {FC,  useState} from 'react';
import { ChangeClientFormProps } from './intrface';
import classes from './changeclientform.module.scss'
import { MyInput } from '../../../components/MyInput';
import { useAppDispatch } from '../../../store/reduxHooks';

export const ChangeClientForm: FC <ChangeClientFormProps> = (props) => {
    const {id, handleClose, data} = props;
    const {name, phone, inn, ogrn, kpp} = data


    const [clientName, setClientName] = useState<string>(name)
    const [clientPhone, setClientPhone] = useState<string>(phone)
    const [clientINN, setClientINN] = useState<string>(inn)
    const [clientOGRN, setClientOGRN] = useState<string>(ogrn)
    const [clientKPP, setClientKPP] = useState<string>(kpp)

    const dispatch = useAppDispatch();
    // const handleClick = () => {
    //     if ( clientName.length > 0 ) {
    //             dispatch(changeClient({
    //                 clientName,
    //                 clientPhone,
    //                 clientINN,
    //                 clientOGRN,
    //                 clientKPP,
    //                 id
    //             }))
    //         } else {alert('Заполните все поля')}
    //         if(data) {
    //             handleClose()
    //             setClientName('')
    //             setClientPhone('')
    //             setClientINN('')
    //             setClientOGRN('')
    //             setClientKPP('')
    //         }
    // }

  return (
    <div className={classes.root}>
        <div className={classes.content}>
            {data ? (
                <>
                    <MyInput 
                        value={clientName}
                        type={'text'}
                        handleInput={setClientName}
                        inputName={'Имя'}
                        placeholder={name} 
                    />
                    <MyInput 
                        value={clientPhone}
                        type={'text'}
                        handleInput={setClientPhone}
                        inputName={'Телефон'}
                        placeholder={phone} 
                    />
                    <MyInput 
                        value={clientINN}
                        type={'number'}
                        handleInput={setClientINN}
                        inputName={'ИНН'}
                        placeholder={inn} 
                    />

                    <MyInput 
                        value={clientOGRN}
                        type={'number'}
                        handleInput={setClientOGRN}
                        inputName={'ОГРН'}
                        placeholder={ogrn} 
                    />
                    <MyInput 
                        value={clientKPP}
                        type={'number'}
                        handleInput={setClientKPP}
                        inputName={'КПП'}
                        placeholder={kpp} 
                    />
                    
                </>
            ) : null}
            {/* <button className={classes.button} onClick={handleClick}>Изменить</button> */}
        </div>
    </div>
  )
}
