import {FC, useState} from 'react';
import { ContactCardProps } from './interface';
import classes from './contactcard.module.scss';
import {HiPhone, HiMail} from 'react-icons/hi';
import cx from 'classnames';
import {BsTrash, BsPencilSquare} from 'react-icons/bs';
import { id } from 'date-fns/locale';
import { useHandleDeleteContacts } from '../../hooks/useClients';
import { useParams } from 'react-router';


export const ContactCard: FC<ContactCardProps> = (props) => {
  const {data, constactId} = props;
  const {name, job, phone, mail} = data;
  const {id} = useParams()

  const [show, setShow] = useState(false)
  const contactName = name
  const contactJob = job
  const contactMail = mail
  const contactPhone = phone

  const deleteContact =  useHandleDeleteContacts(id, contactName, contactJob, contactMail, contactPhone, constactId)
  const handleDeleteClick = () => {
    deleteContact()
  }

  return (
    <div className={classes.root}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >

      <div className={classes.name}>
        {name}
      </div>
      <div className={classes.job}>
        {job}
      </div>
      <div className={classes.contacts}>
        <HiPhone className={classes.icon} size={16} />
        {phone}
      </div>
      <div className={classes.contacts}>
       <HiMail className={classes.icon} size={16} />
        {mail}
      </div>
      <div className={cx(classes.buttonBox, show === true && classes.buttonBoxShown)}>
        <button className={classes.button}>
          <BsPencilSquare size={20}/>
        </button>
        <button className={classes.button} onClick={handleDeleteClick}>
          <BsTrash size={20}/>
        </button>
      </div>
      
    </div>
  )
}