import { FC, useState } from 'react';
import { ArchivedCaseScreenProps } from './interface';
import classes from './archivedcasescreen.module.scss';
import { UserCard } from '../../UserUi/UserCard';
import { MdCalendarMonth } from 'react-icons/md';
import { BillingItem } from '../../../components/BillingItem';
import cx from 'classnames';
import {RiCloseFill} from 'react-icons/ri';
import { useAppDispatch, useAppSelector } from '../../../store/reduxHooks';
import { TodoClientItem } from '../../Clientui/TodoClientItem';
import {SlDocs} from 'react-icons/sl';
import {RiInformationLine} from 'react-icons/ri';
import {HiOutlineCheckCircle} from 'react-icons/hi2';
import {BiArchiveOut} from 'react-icons/bi'
import { useNavigate } from 'react-router';
import { Case } from '../../../api/interface';


 export const ArchivedCaseScreen: FC<ArchivedCaseScreenProps> = (props) => {
    const { data} = props;
    const { id, name, clientId} = data;
    const users = useAppSelector(state => state.users.users);

    const [informationMenuOpen, setInformationMenuOpen] = useState(true);
    const handleOpenInfoMenuClick = () => setInformationMenuOpen(value => !value);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const clients = useAppSelector(state => state.client);
    const cases = useAppSelector(state => state.cases);
    const myArchivedCase = cases.find((item) => item.id === id);
    const myCompletedCase = cases.find((item) => item.id === id);

    // const handleDeArchivedClick = () => {
    //     if(myArchivedCase) {
    //         dispatch(deArchived({clientId, id}))
    //         navigate(`/cases/${id}`)
    //     } else {
    //         dispatch(deCompleted({clientId, id}))
    //         navigate(`/cases/${id}`)
    //     }
    // }

    
    // const handleDeCompleteCaseClick =() => {
    //     if (myCompletedCase) {
    //         dispatch(deCompleted({clientId, id}))
    //         navigate(`/cases/${id}`)
    //     } else {alert('Архивные дела нельзя завершать, обратитесь к администратору')}
    // }

  return (
<div className={classes.root}>
         <div className={classes.header}>
            <div className={classes.task}>
                <div className={classes.task}>
                    {}
                </div>
                <div className={classes.description}>
                </div>
            </div>
            <div className={classes.clientRoot}>
                <div className={classes.client}>
                    {name}

                    <div className={classes.clientDescription}>
                    </div>
                </div>
                <div className={classes.buttonBox}>
                        <div className={classes.button} title='Завершить дело' >
                            <HiOutlineCheckCircle size={22}/>
                        </div>
                        <div className={classes.button} title='Информация' onClick={handleOpenInfoMenuClick}>
                            <RiInformationLine size={22}/>
                        </div>
                        <div className={classes.button} >
                        <SlDocs size={22} title='Документы' />
                        </div>
                        <div className={classes.button} >
                        <BiArchiveOut size={22} title='Вернуть из архива' />
                        </div>
                </div>
            </div>
        </div>
       
        {/* Доп Информация о кейсе */}
        <div className={cx(classes.popUp, informationMenuOpen === true && classes.popUpClose)} onClick={handleOpenInfoMenuClick}>
            <div className={classes.infoMenu} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleOpenInfoMenuClick}>
                    <RiCloseFill size={22} />
                </button>
                <div className={classes.infoBlock}>
                        <div className={classes.infoBlockSplit}>
                            <div className={classes.annotationTitle}>
                            Аннотация
                            </div>
                            <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit debitis itaque distinctio voluptatum quo esse illo incidunt? Quas, necessitatibus? Beatae similique quaerat at quo blanditiis. Dignissimos suscipit dolorum quos, quia earum porro pariatur aperiam ut cupiditate nemo odit aut ipsam et blanditiis aliquam, maiores fugit rem, quidem minima necessitatibus? Recusandae!</div>
                        </div>
                        <div className={classes.infoBlockSplit}>
                        <div className={classes.annotationTitle}>
                            План действий
                            </div>
                            <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit debitis itaque distinctio voluptatum quo esse illo incidunt? Quas, necessitatibus? Beatae similique quaerat at quo blanditiis. Dignissimos suscipit dolorum quos, quia earum porro pariatur aperiam ut cupiditate nemo odit aut ipsam et blanditiis aliquam, maiores fugit rem, quidem minima necessitatibus? Recusandae!</div>
                        </div>
                    </div>
            </div>
        </div>
    
        <div className={classes.section}>
            <div className={classes.users}>
                <div className={classes.userBox}>

                </div>
            </div>
            <div className={classes.calendar}>
                <MdCalendarMonth size={150} />
            </div>
        </div> 
        
        
        {/* <div className={classes.content}>
            <div className={classes.todos}>
                {tasks.map((task) => (
                    <TodoClientItem 
                    clientId={clientId} 
                    data={task} 
                    key={task.id} 
                    caseId={id}
                    />  
                ))}  
            </div>
            <div className={classes.todos}>
            </div>
        </div> */}
    </div>
  )
}
