import {FC} from "react";
import { MyDashboardProps } from "./interface";
import classes from './mydashboard.module.scss';
import { useAppSelector } from "../../store/reduxHooks";
import { CaseItem } from "../../ui/CaseUi/CaseItem";
import { ClientItem } from "../../ui/Clientui/ClientItem";
import { TodoDashboardItem } from "../../ui/MyDashboardUi/TodoDashboardItem";
import { UserCard } from "../../ui/UserUi/UserCard";
import { SmallCalendar } from "../../ui/MyDashboardUi/SmallCalendar";
import { BillingCaseInformation } from "../../ui/MyDashboardUi/BillingCaseInformation";
import { useAuth } from "../../hooks/useAuth";
import { Navigate } from "react-router";
import { useFindMyUser } from "../../hooks/useUsers";
import { DisputeItem } from "../../ui/DisputesUi/DisputeItem";
import { Task } from "../../api/interface";


export const MyDashboard: FC<MyDashboardProps> = (props) => {
  const {isAuth} = useAuth()

  const myUser = useFindMyUser()

  const clients = useAppSelector(state => state.client);
  const cases = useAppSelector(state => state.cases);
  const disputes = useAppSelector(state => state.disputes);
  const activeDisputes = disputes.filter((disput) => disput.isActive === true)

  const myDisputes = activeDisputes.filter((disput) => disput.lawyers.includes(myUser))
  const myDisputesIds = myDisputes.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.caseId], [])

  const myCases = cases.filter((cas) => myDisputesIds.includes(cas.id))
  const myClientsIds = myCases.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.clientId], [])

  const myClients = clients.filter((client) => myClientsIds.includes(client.id))

  const users = useAppSelector(state => state.users.users);
  const usersWithoutYou = users.filter((user) => user.id !== myUser)

  const disputesTasks = myDisputes.reduce<Task[]>((acc, arrayItem) => [...acc, ...arrayItem.tasks], [])
  const casesTasks = myCases.reduce<Task[]>((acc, arrayItem) => [...acc, ...arrayItem.tasks], [])
  const clientsTasks = myClients.reduce<Task[]>((acc, arrayItem) => [...acc, ...arrayItem.tasks], [])

  const allTasks = [...disputesTasks, ...casesTasks, ...clientsTasks]
  const unCompletedTasks = allTasks.filter((task) => task.isCompleted === false)

  return isAuth ? (
    <div className={classes.root}>
        <div className={classes.content}>
          <div className={classes.firstContent}>
            {unCompletedTasks.length > 0 && (
                <><div className={classes.title}>
              Задачи
            </div><div className={classes.todos}>
                {unCompletedTasks.map((task) => (<TodoDashboardItem data={task} key={task.id} />))}
              </div></>
            )}
            
            <div className={classes.title}>
              Биллинг
            </div>
            <div className={classes.billing}>
              {myDisputes.map((cas) => (<BillingCaseInformation data={cas} key={cas.id} />))}
            </div>

          <div className={classes.title}>
          Споры
          </div>
          <div className={classes.disputes}>
              {myDisputes.map((disput) => (<DisputeItem data={disput} disputeId={disput.id} key={disput.id} />))}
          </div>
          </div>
          <div className={classes.secondContent}>
            <div className={classes.title}>
                Дела
            </div>
            <div className={classes.cases}>
              {myCases.map((cas) => (<CaseItem caseId={cas.id} data={cas} key={cas.id} />))}
            </div>
            <div className={classes.title}>
                Calendar today
            </div>
            <div className={classes.calendar}>
                <SmallCalendar />
            </div>

          </div>
          <div className={classes.thirdContent}>
          <div className={classes.title}>
          Клиенты
          </div>
            <div className={classes.clients}>
              {myClients.map((client) => (<ClientItem clientId={client.id} data={client} key={client.id} />))}
            </div>
            <div className={classes.title}>
          Коллеги
          </div>
            <div className={classes.users}>
              {usersWithoutYou.map((user) => (
                <UserCard data={user} userId={user.id} key={user.id} />
              ))}            
          </div>
          </div>
        </div>
    </div>
  ) : (<Navigate to='/login' />)
};
