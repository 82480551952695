import {FC,  useState, useEffect} from 'react';
import { ChangeCaseFormProps } from './intrface';
import classes from './changecaseform.module.scss'
import { MyInput } from '../../../components/MyInput';
import { useAppDispatch } from '../../../store/reduxHooks';


export const ChangeCaseForm: FC <ChangeCaseFormProps> = (props) => {
    const {handleClose,  data} = props;
    const {clientId, id, name} = data

    const [caseName, setCaseName] = useState<string>(name)
    // const [caseTask, setCaseTask] = useState<string>(task)
    // const [caseNumber, setCaseNumber] = useState<string>(number)
    // const [clientPaymentSystem, setClientPaymentSystem] = useState<string>(paymentsystem)
    // const [clientBillSum, setClientBillSum] = useState<number>(billSum)
    // const [clientCourtPrice, setClientCourtPrice] = useState<string>(courtPrice)
    // const [clientLawyerPrice, setClientLawyerPrice] = useState<number>(lawyerPrice)
    // const [clientCompensationSystem, setClientCompensationSystem] = useState<string>(compensationSystem)
    useEffect(() => {
        setCaseName(name)
        // setCaseTask(task)
        // setCaseNumber(number)
        // setClientPaymentSystem(paymentsystem)
        // setClientBillSum(billSum)
        // setClientCourtPrice(courtPrice)
        // setClientLawyerPrice(lawyerPrice)
        // setClientCompensationSystem(compensationSystem)
    }, [data])

    const dispatch = useAppDispatch();
    // const handleClick = () => {
    //     if ( caseName.length > 0 
    //         // &&
    //         // caseTask.length > 0 &&
    //         // caseNumber.length > 0 &&
    //         // clientPaymentSystem.length > 0 &&
    //         // clientBillSum > 0 &&
    //         // clientCourtPrice.length > 0 &&
    //         // clientLawyerPrice > 0 &&
    //         // clientCompensationSystem.length > 0
    //         ) {
    //             dispatch(changeCase({
    //                 clientId,
    //                 id,
    //                 caseName,
    //                 // caseTask,
    //                 // caseNumber,
    //                 // clientPaymentSystem, 
    //                 // clientBillSum,
    //                 // clientCourtPrice, 
    //                 // clientLawyerPrice,
    //                 // clientCompensationSystem,
    //             }))
    //         } else {alert('Заполните все поля')}
        
    //     if(data) {
    //         handleClose()
    //         setCaseName('')
    //         // setCaseTask('')
    //         // setCaseNumber('')
    //         // setClientPaymentSystem('')
    //         // setClientBillSum(0)
    //         // setClientCourtPrice('')
    //         // setClientLawyerPrice(0)
    //         // setClientCompensationSystem('')
    //     }
    // }

  return (
    <div className={classes.root}>
        <div className={classes.content}>
            {data ? (
                <>
                    <MyInput 
                        value={caseName}
                        type={'text'}
                        handleInput={setCaseName}
                        inputName={'Название дела'}
                        placeholder={name} 
                    />
                    {/* <MyInput 
                        value={caseTask}
                        type={'text'}
                        handleInput={setCaseTask}
                        inputName={'Задача'}
                        placeholder={task} 
                    />
                    <MyInput 
                        value={caseNumber}
                        type={'text'}
                        handleInput={setCaseNumber}
                        inputName={'Номер дела'}
                        placeholder={number} 
                    />

                    <MyInput 
                        value={clientPaymentSystem}
                        type={'text'}
                        handleInput={setClientPaymentSystem}
                        inputName={'Система оплаты'}
                        placeholder={paymentsystem} 
                    />
                    <MyInput 
                        value={clientBillSum}
                        type={'number'}
                        handleInput={setClientBillSum}
                        inputName={'Сумма выставления счета'}
                        placeholder={billSum.toString()} 
                    />
                    <MyInput 
                        value={clientCourtPrice}
                        type={'number'}
                        handleInput={setClientCourtPrice}
                        inputName={'Стоимость судебного заседания'}
                        placeholder={courtPrice} 
                    />
                    <MyInput 
                        value={clientLawyerPrice}
                        type={'number'}
                        handleInput={setClientLawyerPrice}
                        inputName={'Стоимость часа работы юриста'}
                        placeholder={lawyerPrice.toString()} 
                    />
                    <MyInput 
                        value={clientCompensationSystem}
                        type={'text'}
                        handleInput={setClientCompensationSystem}
                        inputName={'Способ компенсации накладных расходов'}
                        placeholder={compensationSystem} 
                    /> */}
                </>
            ) : null}
 
                    {/* <button className={classes.button} onClick={handleClick}>Изменить</button> */}
        </div>
    </div>
  )
}
