import {FC} from "react";
import { BillsProps } from "./interface";
import { useAppSelector } from "../../store/reduxHooks";
import { Bill, Case, Disput } from "../../api/interface";
import { BillItem } from "../../ui/BillUi/BillItem";
import classes from './bills.module.scss'

export const Bills: FC<BillsProps> = (props) => {
  const clients = useAppSelector(state => state.client);
  const cases = useAppSelector(state => state.cases);
  const disputes = useAppSelector(state => state.disputes);
  const bills = disputes.reduce<Bill[]>((acc, arrayItem) => [...acc, ...arrayItem.bills], []);

  return (
    <div className={classes.root}>
    <div className={classes.navigationPanel}>
      <div className={classes.list}>
      {bills.map((bill) => (
        <BillItem key={bill.id} clientId={bill.clientId} teg={bill.teg} totalHours={bill.totalHours} totalSumm={bill.totalSumm} billingItems={bill.billingItems} id={bill.id} />
      ))}
        </div>
    </div>
      <div className={classes.description}>
      </div>

  </div>

  )
}
