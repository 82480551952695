import { configureStore  } from '@reduxjs/toolkit';
import userReducer from './slices/usersSlice';
import todoReducer from './slices/todoSlice';
import chatsReducer from './slices/chatSlice';
import clientReducer from './slices/clientSlice'
import userAuthReducer from './slices/userAuthSlice';
import caseReducer from './slices/caseSlice';
import disputeReducer from './slices/disputesSlice';
import tasksReducer from './slices/tasksSlice'


export const store = configureStore({
    reducer: {
        users: userReducer,
        todos: todoReducer,
        chats: chatsReducer,
        authuser: userAuthReducer,
        client: clientReducer,
        cases: caseReducer,
        disputes: disputeReducer,
        tasks: tasksReducer
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch