import React, { FC } from 'react';
import { MessageProps } from './interface';
import cx from 'classnames';
import classes from './message.module.scss'
import { useAppSelector } from '../../../store/reduxHooks';

export const Message: FC<MessageProps> = (props) => {
    const {data} = props;
    const {message, userId} = data;
    const user = useAppSelector(state => state.authuser)
    const myId = user.id
    const users = useAppSelector(state => state.users.users)
    const messageUser = users.find((user) => user.id === userId)
  return (
    <div className={cx(classes.user, userId === myId && classes.youUser)}>
      <div className={classes.userName}>
      {messageUser?.name} {messageUser?.surName}

      </div>
          <div className={cx(classes.root, userId === myId && classes.youroot)}>
        {message}
    </div>
    </div>

  )
}
