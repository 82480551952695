import {FC} from 'react';
import { BillingItemProps } from './interface';
import classes from './billingitemprops.module.scss';


export const BillingItem: FC<BillingItemProps> = (props) => {
    const {data} = props;
    const {name, price, hours} = data;

    return (
        <div>
            <div className={classes.todoItem}>
                <div className={classes.item}>
                    <div className={classes.title}>
                        <div>{name}</div><div className={classes.miniTitle}>{hours} час</div><div className={classes.miniTitle}>{price} рублей</div>
                    </div>
                </div>
            </div>
        </div> 
  )
}