import {FC} from 'react';
import { TodoDashboardItemProps } from './interface';
import classes from './todoclientitem.module.scss';
import {AiFillCheckCircle} from 'react-icons/ai';
import cx from 'classnames';


export const TodoDashboardItem: FC<TodoDashboardItemProps> = (props) => {
    const {data} = props;
    const {title, isCompleted} = data;

    return (
        <div>
            <div className={classes.todoItem}>
            <div className={classes.item}>
                <div className={cx(classes.icon, isCompleted && classes.iconCompleted)}>
                    <AiFillCheckCircle size={25} />
                </div>
                <div className={classes.title}>
                    {title}
                </div>
            </div>
        </div>
        </div>
        
  )
}