import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { Task } from '../../api/interface';

const initialState: Task[] = []

export const caseSlice = createSlice({
    name: 'tasks',
    initialState,
    reducers: {
        getTasks(state, action) {
            return action.payload
        }
    }
})

export const {
    getTasks
} = caseSlice.actions
export default caseSlice.reducer