import {FC, useState, useEffect, useCallback} from 'react';
import { AddContactFormProps } from './interface';
import classes from './addclientform.module.scss'
import { MyInput } from '../../../components/MyInput';
import { useAppDispatch, useAppSelector } from '../../../store/reduxHooks';
import InputMask from 'react-input-mask';
import { useHandleAddContacts } from '../../../hooks/useClients';


const AddContactForm:FC <AddContactFormProps> = (props) => {
    const {clientId, handleClose} = props;
    const [contactName, setContactName] = useState<string>([] as any);
    const [contactJob, setContactJob] = useState<string>([] as any);
    const [contactMail, setContactMail] = useState<string>([] as any);
    const [contactPhone, setContactPhone] = useState<string>([] as any);
    const id = clientId;
    const addContactItem = useHandleAddContacts(id, contactName, contactJob, contactMail, contactPhone)

    const handleClick = () => {
        addContactItem()
        handleClose()
        setContactName('')
        setContactJob('')
        setContactMail('')
        setContactPhone('')
    }



  return (
    <div className={classes.root}>
        <div className={classes.content}>
        <div className={classes.title}>
                Контакт 
        </div>
                    <MyInput 
                        value={contactName}
                        type={'text'}
                        handleInput={setContactName}
                        inputName={'ФИО'}
                        placeholder={'Введите ФИО'} 
                    />
                    <MyInput 
                        value={contactJob}
                        type={'text'}
                        handleInput={setContactJob}
                        inputName={'Должность'}
                        placeholder={'Введите должность'} 
                    />
                    <MyInput 
                        value={contactMail}
                        type={'text'}
                        handleInput={setContactMail}
                        inputName={'E-mail'}
                        placeholder={'Введите E-mail'} 
                    />
                    <div>Телефон</div>
                <InputMask 
                    className={classes.input}
                    mask='+7(999) 999-99-99' 
                    placeholder='+7(999) 123-45-67'
                    value={contactPhone} 
                    onChange={(e) => setContactPhone(e.target.value)}>
                </InputMask>
                    
                    <button className={classes.button} onClick={handleClick}>Добавить</button>

        </div>
    </div>
  )
}

export default AddContactForm