import {FC, useState, useEffect} from 'react';
import { DisputeScreenProps } from './interface';
import classes from './disputescreen.module.scss'
import { useAppSelector } from '../../../store/reduxHooks';
import { UserCard } from '../../UserUi/UserCard';
import { ButtonBox } from '../../ButtonBox';
import {MdCalendarMonth} from 'react-icons/md';
import { TodoClientItem } from '../../Clientui/TodoClientItem';

import Select from 'react-select'
import { BillInput } from '../../../components/BillInput';
import { BillingItem } from '../../../components/BillingItem';
import cx from 'classnames';
import {RiCloseFill} from 'react-icons/ri';
import { BsPlusCircleFill } from 'react-icons/bs';
import { selectOptions } from '../../../api/data';
import { MoreInformationEditMenu } from '../../../components/MoreInformationEditMenu';
import { AddTodoComponent } from '../../../components/AddTodoComponent';
import { useHandleAddBillingItem, useHandleAddCustomBillingItem, useHandleAddUserToDispute, useHandleCompleteDisput } from '../../../hooks/useCase';
import CompletedTasksScreen from '../CompletedTasksScreen';

export const DisputeScreen: FC<DisputeScreenProps> = (props) => {
    const { data} = props;
    const { id, caseId, name, cas, creator, number, lawyers, billingItems, lawyerPrice, courtPrice, annotation, plan, billSum, tasks, teg} = data;
    const users = useAppSelector(state => state.users.users);
    const magicUser = users.filter((user) => lawyers.includes(user.id));
    const user = useAppSelector(state => state.authuser)

    const completedTasks = tasks.filter((item) => item.isCompleted === true);
    const unCompletedTasks = tasks.filter((item) => item.isCompleted === false);

    const admin = 'FGNTMtdZJuZ5TinR9sJHrf5nPED2'

    const totalHours = billingItems.reduce((acc, billItem) => acc + billItem.hours, 0)
    const billingSumm = totalHours * lawyerPrice
    useEffect(() => {
        if(billingSumm >= billSum) {
            alert('Самое время выставить счет.')
        }
    })

    const [userChoice, setUserChoice] = useState<any>([] as any)

    const [billCountStr, setBillCountStr] = useState<number>([] as any);
    const billTitle = userChoice.value;
    const lawyerBillingPrice = lawyerPrice;

    const [open, setopen] = useState(true);
    const handleOpenCloseClick = () => setopen(value => !value)
    const billCount = + billCountStr
    const totalBillPrice = billCount * lawyerBillingPrice

    const [userAddChoice, setUserAddChoice] = useState<any>([] as any);

    const cases = useAppSelector((state) => state.cases)
    const myCase = cases.find((cas) => cas.id === caseId)

    const addBillingItem = useHandleAddBillingItem(id, totalBillPrice, billTitle, billCount)

    const handleAddBillingItemToDispute = () => {
        if( billTitle !== 'other') {
            addBillingItem()
            setBillCountStr(0)
        } else {
            handleOpenCloseClick()
        }
    }

    const handleBillKeyPress = (e: any) => {
        if(e.key === 'Enter'){
            handleAddCustomBillingItemToDispute()
        }
    }

    const addCustomBillingItem = useHandleAddCustomBillingItem(id, totalBillPrice, userAddChoice, billCount)

    const handleAddCustomBillingItemToDispute = () => {
        addCustomBillingItem()
        setBillCountStr(0)
        handleOpenCloseClick()
    }



    const [archiveMenuOpen, setArchiveMenuOpen] = useState(true)
    const handleOpenArchiveMenuClick = () => setArchiveMenuOpen(value => !value)

    const [deleteMenuOpen, setDeleteMenuOpen] = useState(true);
    const handleOpenDeleteMenuClick = () => setDeleteMenuOpen(value => !value)

    const [informationMenuOpen, setInformationMenuOpen] = useState(true);
    const handleOpenInfoMenuClick = () => setInformationMenuOpen(value => !value);

    const [addUserOpen, setAddUserOpen] = useState(true);
    const handleAddUserClick = () => setAddUserOpen(value => !value)

    const [changeMenuOpen, setChangeMenuOpen] = useState(true);
    const handleOpenChangeMenuClick = () => setChangeMenuOpen(value => !value)

    const [userOptionChoice, setUserOptionChoice] = useState<any>([] as any)
    const addUserValue = userOptionChoice.value


    const addUserToDispute = useHandleAddUserToDispute(id, addUserValue)
    const handleAddUserDispatchClick = () => {
        if(addUserValue) {
            addUserToDispute()
            setUserOptionChoice({})         
            handleAddUserClick()
        }
    }
    
    const [completeCaseMenuOpen, setCompleteCaseMenuOpen] = useState(true)
    const handleCompleteCaseMenuOpenClick = () => setCompleteCaseMenuOpen(value => !value)
    
    const addUsersArray = users.filter((item) => !lawyers.includes(item.id))

    const [inputValue, setInputValue] = useState([] as any)

    const completeDispute = useHandleCompleteDisput(id, inputValue)

    const handleCompleteCase = () => {
        completeDispute()
        handleCompleteCaseMenuOpenClick()
        setInputValue('')
    }
    const [taskMenuOpen, setTaskMenuOpen] = useState(true);
    const handleOpenTaskInfoClick = () => setTaskMenuOpen(value => !value)


  return (
    <div className={classes.root}>
         <div className={classes.header}>
            <div className={classes.task}>
                <div className={classes.task}>
                    {cas}
                </div>
                <div className={classes.description}>
                    <div className={classes.title}>
                        {myCase?.name}
                    </div>
                    <div className={classes.titleforKad}>
                        Номер дела:                          
                        <span className={classes.linkToKad} onClick={() => window.open(`https://kad.arbitr.ru/Card?number=${number}`)}>
                        {myCase?.number}
                        </span>
                    </div>
                <div>
                        Стоимость часа: {lawyerBillingPrice}
                    </div>
                    <div>
                        Стоимость судебного заседания: {courtPrice}
                    </div>
                    <div>
                        Сумма выставления счета {billSum}
                    </div>
                </div>
            </div>
            <div className={classes.clientRoot}>
                <div className={classes.client}>
                    {name}
                    <div className={cx(classes.tagMark, 
                        teg === 'Взыскание задолженности' && classes.tagMarkDolgi,
                        teg === 'Деликты' && classes.tagMarkDelicti,
                        teg === 'Банкротство' && classes.tagMarkBancrot,
                        teg === 'Недвижимость' && classes.tagMarkRealEstate,
                        teg === 'Споры с гос. и имущ. организациями' && classes.tagMarkGosSpori,
                        teg === 'Корпоративные споры' && classes.tagMarkKorporati,
                        teg === 'Налоговые споры' && classes.tagMarkNalogi,
                        teg === 'Интеллектуальная собственность' && classes.tagMarkIntellect,
                        )}>
                        {teg}
                    </div>
                    <div className={classes.clientDescription}>
                        Сумма к биллингу {billingSumm} <br />
                    </div>
                </div>
                <ButtonBox 
                handleOpenTaskInfoClick={handleOpenTaskInfoClick}
                handleCompleteCaseClick={handleCompleteCaseMenuOpenClick}
                handleOpenInfoMenuClick={handleOpenInfoMenuClick}
                handleOpenChangeMenuClick={handleOpenChangeMenuClick}
                handleArchiveCaseClick={handleOpenArchiveMenuClick}
                handleOpenDeleteMenuClick={handleOpenDeleteMenuClick} 
                />
            </div>
        </div>
        {/* Завершить кейс */}
        <div className={cx(classes.popUp, completeCaseMenuOpen === true && classes.popUpClose)}>
            <div className={classes.completedMenu} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleCompleteCaseMenuOpenClick}>
                    <RiCloseFill size={22} />
                </button>
                <div className={classes.annotationTitle}>
                    Завершить дело
                </div>
                <div className={classes.completeCase}>
                    <textarea wrap='true' className={classes.myArea} value={inputValue} onChange={e => setInputValue(e.target.value)}  name="" id="" placeholder='Введите заключение'></textarea>
                </div>
                <button className={classes.buttonComplete} onClick={handleCompleteCase}>
                    Завершить
                </button>
            </div>
        </div>

        {/* Доп Информация о кейсе */}
        <div className={cx(classes.popUp, informationMenuOpen === true && classes.popUpClose)} >
            <div className={classes.infoMenu} onClick={e => e.stopPropagation()}>
                <MoreInformationEditMenu 
                handleOpenInfoMenuClick={handleOpenInfoMenuClick} 
                annotation={annotation} 
                plan={plan}
                fatherID={id}
                />
            </div>
        </div>

        {/* Завершенные задачи */}
        <div className={cx(classes.popUp, taskMenuOpen === true && classes.popUpClose)} >
            <div className={classes.taskMenu} onClick={e => e.stopPropagation()}>
                <CompletedTasksScreen handleOpenTaskInfoClick={handleOpenTaskInfoClick} data={data} lawyers={lawyers} />
            </div>
        </div>

        {/* Архивировать кейс */}
        {/* <div className={cx(classes.popUp, archiveMenuOpen === true && classes.popUpClose)} onClick={handleOpenArchiveMenuClick}>
            <div className={classes.deleteCaseMenu} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleOpenArchiveMenuClick}>
                    <RiCloseFill size={22} />
                </button>
                Вы уверены что хотите перенести в архив дело "{name}" у клиента {client}?
                <div className={classes.deleteButtons}>
                    <button onClick={handleArchiveCaseClick}>Да</button> <button onClick={handleOpenArchiveMenuClick}>Нет</button>
                </div>
            </div>
        </div> */}
        {/* Удалить Кейс */}
        {/* <div className={cx(classes.popUp, deleteMenuOpen === true && classes.popUpClose)} onClick={handleOpenDeleteMenuClick}>
            <div className={classes.deleteCaseMenu} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleOpenDeleteMenuClick}>
                    <RiCloseFill size={22} />
                </button>
                Вы уверены что хотите удалить дело "{name}" у клиента {client}?
                <div className={classes.deleteButtons}>
                    <button onClick={handleDeleteCaseClick}>Да</button> <button onClick={handleOpenDeleteMenuClick}>Нет</button>
                </div>
            </div>
        </div> */}

        {/* Изменить Кейс */}
        {/* <div className={cx(classes.popUp, changeMenuOpen === true && classes.popUpClose)}>
            <div className={classes.changeCaseMenu} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleOpenChangeMenuClick}>
                    <RiCloseFill size={22} />
                </button>
                Изменить "{name}"
                <ChangeCaseForm 
                      caseId={id}
                      clientId={clientId}
                      data={data}
                      name={name}
                      task={task}
                      handleClose={handleOpenChangeMenuClick}
                      number={number} 
                      paymentsystem={paymentsystem} 
                      billSum={billSum} 
                      courtPrice={courtPrice} 
                      lawyerPrice={lawyerPrice} 
                      compensationSystem={compensationSystem} />
            </div>
        </div> */}

        <div className={classes.section}>
            <div className={classes.users}>
                <div className={classes.userBox}>
                {magicUser.map((user) => (
                    <UserCard data={user} userId={user.id} key={user.id}/>
                ))}
                {user.id === creator || user.id === admin   ? <div className={classes.addLawyer} onClick={handleAddUserClick}>
                    <BsPlusCircleFill size={40}  />
                </div> : null}
                </div>
            </div>
            <div className={classes.calendar}>
                <MdCalendarMonth size={150} />
            </div>
        </div> 
        <div className={classes.inputBlock}>
                    <div className={classes.inputBox}>
                    <AddTodoComponent id={id} />

                    </div>
                    <div className={classes.inputBox}>
                    <div>
                    <Select
                        isClearable={false}
                        defaultValue={{label: 'Выберете услугу', value: ''}}
                        className={classes.select}
                        classNamePrefix="select"
                        options={selectOptions}
                        onChange={(chioce) => setUserChoice(chioce)}
                    />
                    </div>
                    <BillInput 
                        value={billCount}
                        type={number}
                        handleInput={setBillCountStr}
                        placeholder={0} 
                    />
                    <button onClick={handleAddBillingItemToDispute} className={classes.button} >Добавить</button>
                    <button  className={cx(classes.addButtonHide, billingSumm >= billSum && classes.addButton)}>Счет</button>
                    </div>      
        </div>
        {/* Добавить билиинг не из сущетвующего */}
        <div className={cx(classes.popUp, open === true && classes.popUpClose)} onClick={handleOpenCloseClick}>
            <div className={classes.form} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleOpenCloseClick}>
                    <RiCloseFill size={22} />
                </button>
                <div className={classes.popUpInput}>
                    <input 
                    value={userAddChoice}
                    className={classes.input}
                    placeholder='Что сделано'
                    onChange={(e) => setUserAddChoice(e.target.value)}
                    onKeyDown={handleBillKeyPress}
                    />
                    <button className={classes.button} onClick={handleAddCustomBillingItemToDispute}>Добавить</button>
                </div>
            </div>
        </div>
        <div className={classes.content}>
            <div className={classes.todos}>
                {unCompletedTasks.length ? (<div>
                    Задачи
                    {unCompletedTasks.map((task) => (
                        <TodoClientItem fatherId={id} data={task} key={task.id} />  
                    ))}
                </div>) : null}

                {completedTasks.length ? (<div>
                    Выполненные задачи
                    {completedTasks.map((task) => (
                        <TodoClientItem fatherId={id} data={task} key={task.id} />  
                    ))}
                </div>) : null}

            </div>
            <div className={classes.todos}>
                Биллинг
                <div>{billingItems.map((item) => (
                    <BillingItem data={item} key={item.id} />
                ))}</div>
            </div>
        </div>
        <div className={cx(classes.popUp, addUserOpen === true && classes.popUpClose)} onClick={handleAddUserClick}>
            <div className={classes.form} onClick={e => e.stopPropagation()}>
            <button className={classes.closeIcon} onClick={handleAddUserClick}>
                    <RiCloseFill size={22} />
                </button>
                <div className={classes.popUpInput}>
                    <Select
                        defaultValue={{label: 'Добавить пользователя', value: ''}}
                        name="colors"
                        options={addUsersArray}
                        className={classes.select}
                        classNamePrefix="select"
                        onChange={(chioce) => setUserOptionChoice(chioce)}
                    />
                    <button onClick={handleAddUserDispatchClick} className={classes.button}>Добавить</button>
                </div>
            </div>
        </div>
    </div>
  )
}
