import {FC, useState, useEffect, useCallback} from 'react';
import { AddCaseFormProps } from './intrface';
import classes from './addcaseform.module.scss'
import { MyInput } from '../MyInput';
import Select from 'react-select'
import { selectTagsOptions } from '../../api/data';
import { useMyUser } from '../../hooks/useUsers';
import { useHandleAddCases,  } from '../../hooks/useCase';
import { useAppSelector } from '../../store/reduxHooks';
import { useParams } from 'react-router';

export const AddCaseForm: FC <AddCaseFormProps> = (props) => {
    const {handleClose, clientId} = props;

    const [caseName, setCaseName] = useState<string>([] as any);
    const [caseTask, setCaseTask] = useState<string>([] as any);
    const [caseNumber, setCaseNumber] = useState<string>([] as any);
    const [tagtoCase, setTagtoCase] = useState<any>([] as any)
    const myCaseTag = tagtoCase.value

    const user = useAppSelector(state => state.authuser)

    
    const addCase = useHandleAddCases(
        clientId,
        caseName,
        caseTask,
        caseNumber,
    )

    const handleClick = useCallback(() => {
        if (myCaseTag) {
            addCase()
            handleClose()
            setCaseName('')
            setCaseTask('')
            setCaseNumber('')
            setTagtoCase({label: '', value: ''})
        } else {alert('Добавьте Тэг')}
    }, [addCase, handleClose, myCaseTag])   


  return (
    <div className={classes.root}>
        <div className={classes.content}>
        <div className={classes.title}>
                Дело 
        </div>
                    <MyInput 
                        value={caseName}
                        type={'text'}
                        handleInput={setCaseName}
                        inputName={'Название дела'}
                        placeholder={'Введите текст'} 
                    />
                    <MyInput 
                        value={caseNumber}
                        type={'text'}
                        handleInput={setCaseNumber}
                        inputName={'Номер дела'}
                        placeholder={'Введите номер'} 
                    />
                    {/* <MyInput 
                        value={clientPaymentSystem}
                        type={'text'}
                        handleInput={setClientPaymentSystem}
                        inputName={'Система оплаты'}
                        placeholder={'Выберете из предложенного'} 
                    />
                    <MyInput 
                        value={clientBillSum}
                        type={'number'}
                        handleInput={setClientBillSum}
                        inputName={'Сумма выставления счета'}
                        placeholder={'Введите сумму'} 
                    />
                    <MyInput 
                        value={clientCourtPrice}
                        type={'number'}
                        handleInput={setClientCourtPrice}
                        inputName={'Стоимость судебного заседания'}
                        placeholder={'Введите сумму'} 
                    />
                    <MyInput 
                        value={clientLawyerPrice}
                        type={'number'}
                        handleInput={setClientLawyerPrice}
                        inputName={'Стоимость часа работы юриста'}
                        placeholder={'Введите сумму'} 
                    />
                    <MyInput 
                        value={clientCompensationSystem}
                        type={'text'}
                        handleInput={setClientCompensationSystem}
                        inputName={'Способ компенсации накладных расходов'}
                        placeholder={'Введите текст'} 
                    /> */}
                    Тэг
                    <Select
                    isClearable={false}
                    className={classes.select}
                    classNamePrefix="select"
                    options={selectTagsOptions}
                    onChange={(chioce) => setTagtoCase(chioce)} 
                    defaultValue={{label: '', value: ''}}
                    />
                    <button className={classes.button} onClick={handleClick}>Добавить</button>
        </div>
    </div>
  )
}
