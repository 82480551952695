import {FC, useState} from 'react';
import { TodoClientItemProps } from './interface';
import classes from './todoclientitem.module.scss';
import {AiFillCheckCircle} from 'react-icons/ai';
import { TbTrashFilled} from 'react-icons/tb';
import cx from 'classnames';
import {MdInfo} from 'react-icons/md'
import {RiCloseFill} from 'react-icons/ri';
import { useHandleDeleteTasks, useHandleToggleTasks, useHandleUnToggleTasks } from '../../../hooks/useCase';
import { useAppSelector } from '../../../store/reduxHooks';
import { useFindMyUser } from '../../../hooks/useUsers';
import dayjs from 'dayjs';

export const TodoClientItem: FC<TodoClientItemProps> = (props) => {
    const {data, fatherId} = props;
    const {title, isCompleted, id, formattedDate, creator, completor, completeTime} = data;

    // const [open, setOpen] = useState(false);
    // const handleOpenCloseChatBar = () => setOpen(value => !value);

    const [toggleOpen, setToggleOpen] = useState(true);
    const handleOpenCloseToggleMenu = () => setToggleOpen(value => !value);

    const users = useAppSelector(state => state.users.users)
    const myUser = users.find((user) => user.id === creator)
    const completedUser = users.find((user) => user.id === completor)

    const completeUser = useFindMyUser()
    const completedTimeForHook = dayjs().valueOf()


    const [openDeleteMenu, setOpenDeleteMenu] = useState(true)
    const handleOpenCloseDeleteMenu = () => setOpenDeleteMenu(value => !value)

    const toggleTask = useHandleToggleTasks(id, isCompleted, fatherId, title, formattedDate, creator, completeUser, completedTimeForHook)
    const unToggleTask = useHandleUnToggleTasks(id, isCompleted, fatherId, title, formattedDate, creator, completor, completeTime)
    
    const handleToggleTodoClick =() => {
        if(isCompleted === false) {
            toggleTask()
        } else {
            handleOpenCloseToggleMenu()
        }
    }

    const handleUnToggleTodoClick = () => {
        unToggleTask()
        handleOpenCloseToggleMenu()
    }

    const deleteTask = useHandleDeleteTasks(id, isCompleted, fatherId, title, formattedDate, creator, completor, completeTime)

    const handleTodoDelete = () => {
        deleteTask()
    }

    function takeStatus() {
        if (isCompleted === false) {
            return 'В работе'
        }
        else {
            return 'Завершено'
        }
    }

    const status = takeStatus()

    const [openInfoMenu, setOpenInfoMenu] = useState(true)
    const handleOpenInfoClick = () => setOpenInfoMenu(value => !value)

    const startDate = dayjs(formattedDate).format('HH:mm DD.MM.YYYY')
    const finishDate = dayjs(formattedDate).format('HH:mm DD.MM.YYYY')
    
    return (
        <div>
            {/* Удаление задачи */}
            <div className={cx(classes.popUp, openDeleteMenu === true && classes.popUpClose)} onClick={handleOpenCloseDeleteMenu}>
                <div className={classes.deleteCaseMenu} onClick={e => e.stopPropagation()}>
                    <button className={classes.closeIcon} onClick={handleOpenCloseDeleteMenu}>
                        <RiCloseFill size={22} />
                    </button>
                    Вы уверены что хотите удалить задачу "{title}"?
                    <div className={classes.deleteButtons}>
                        <button className={classes.button} onClick={handleTodoDelete}>Да</button> <button className={classes.button} onClick={handleOpenCloseDeleteMenu}>Нет</button>
                    </div>
                </div>
             </div>

            {/* Открыть меню */}
            <div className={cx(classes.popUp, openInfoMenu === true && classes.popUpClose)} onClick={handleOpenInfoClick}>
                <div className={classes.infoMenuOpen} onClick={e => e.stopPropagation()}>
                    <button className={classes.closeIcon} onClick={handleOpenInfoClick}>
                        <RiCloseFill size={22} />
                    </button>
                    <div className={classes.titleMenu}>
                    Информационное меню
                    </div>
                    <div className={classes.infoBlock}>
                        <div className={classes.informationBlock}>
                            <div className={classes.infoTitle}>
                            {title}
                            </div>
                        
                        </div>
                        <div className={cx(classes.information, isCompleted === true && classes.informationCompleted)}>
                        {status}
                        </div>
                    </div>
                    <div className={classes.infoBlock}>
                        <div className={classes.informationBlock}>
                            <div>
                                Создано: {myUser?.label}
                            </div>
                            <div>
                                Время: {startDate}
                            </div>
                        </div>
                        <div className={classes.informationBlock}>
                            <div className={cx(classes.show, isCompleted === false && classes.hide)}>
                                <div>
                                    Завершено: {completedUser?.label}
                                </div>
                                <div>
                                    Время: {finishDate}
                                </div>
                            </div>
                        </div>
                        
                    </div>

                </div>
             </div>

            {/* Растоглить */}
            <div className={cx(classes.popUp, toggleOpen === true && classes.popUpClose)} onClick={handleOpenCloseToggleMenu}>
                <div className={classes.deleteCaseMenu} onClick={e => e.stopPropagation()}>
                    <button className={classes.closeIcon} onClick={handleOpenCloseToggleMenu}>
                        <RiCloseFill size={22} />
                    </button>
                    Вы уверены что хотите снять статус "Выполнено" у задачи "{title}"?
                    <div className={classes.deleteButtons}>
                        <button className={classes.button} onClick={handleUnToggleTodoClick}>Да</button> <button className={classes.button} onClick={handleOpenCloseToggleMenu}>Нет</button>
                    </div>
                </div>
             </div>

            <div className={classes.todoItem}>
                <div className={classes.item}>
                    <div className={cx(classes.icon, isCompleted && classes.iconCompleted)}>
                        <AiFillCheckCircle onClick={handleToggleTodoClick} size={25} />
                    </div>
                    <div className={cx(classes.title, isCompleted && classes.titleCompleted)}>
                        {title}
                    </div>
                </div>
                <div className={classes.iconBox}>
                    {/* <div className={classes.chatIcon}>
                        <TbMessageCircle size={25} onClick={handleOpenCloseChatBar} />
                    </div> */}
                    <div className={classes.chatIcon}>
                        <TbTrashFilled size={25} onClick={handleOpenCloseDeleteMenu}/>
                    </div>
                    <div className={classes.chatIcon}>
                        <MdInfo size={25} onClick={handleOpenInfoClick}/>
                    </div>
                </div>
            
            </div>
            {/* <div className={cx(classes.chat, open && classes.chatOpen)}>
                <CaseChat taskId={id} data={data} />
            </div>   */}
        </div> 
  )
}