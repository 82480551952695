import {FC} from 'react';
import { ChatInputProps } from './interface';
import classes from './input.module.scss'

export const ChatInput: FC<ChatInputProps> = ({
  value,
  handleInput,
  handleSubmit,
  placeholder,
  buttonHolder,
}) => {
  const handleKeyPress = (e: any) => {
    if(e.key === 'Enter'){
      handleSubmit()
    }
  }
  return (
    <div className={classes.root}>
        <input className={classes.input} 
          value={value}
          placeholder={placeholder}
          onChange={(e) => handleInput(e.target.value)}
          onKeyDown={handleKeyPress} 
        />
        <button className={classes.button} onClick={() => {handleSubmit()}}>
          {buttonHolder}
        </button>
    </div>
  )
}