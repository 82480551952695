import {FC} from 'react';
import { BillingCaseInformationProps } from './interface';
import classes from './billingcaseinformation.module.scss'

export const BillingCaseInformation: FC<BillingCaseInformationProps> = (props) => {
    const {data} = props;
    const {name, billingItems} = data
    const billingSumm = billingItems.reduce<number>((acc, billItem) => acc + billItem.price, 0)


    return (
    <div className={classes.root}>
        <div className={classes.title}>
            <div>
                {name}
            </div>

            </div>
        <div className={classes.rangeInfo}>
            <input type="range" min={0} max={50000} readOnly={true} value={billingSumm}  className={classes.range} />
            <span>{billingSumm} рублей</span>
        </div>
    </div>
  )
}
