import {FC, useEffect, useState} from 'react';
import { NavBarProps } from './interface';
import classes from './navbar.module.scss';
import {BiUser} from 'react-icons/bi';
import { useAppDispatch, useAppSelector } from '../../store/reduxHooks';
import { removeUser } from '../../store/slices/userAuthSlice';
import { useNavigate } from 'react-router';


export const NavBar: FC<NavBarProps> = (props) => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(state => state.authuser)
    const myId = user.id
    const allUsers = useAppSelector(state => state.users.users)
    const myUser = allUsers.find((user) => user.id === myId)

    
    const text = 'Выйти';
    const [time, setTime] = useState(  new Date().toLocaleString(undefined, {year: 'numeric', month: '2-digit', day: '2-digit', weekday:"long", hour: '2-digit', hour12: false, minute:'2-digit', second:'2-digit'}))
    useEffect(() => {
      const interval = setInterval(() => {
        setTime(new Date().toLocaleString(undefined, {year: 'numeric', month: '2-digit', day: '2-digit', weekday:"long", hour: '2-digit', hour12: false, minute:'2-digit', second:'2-digit'}))
            }, 1000);
      return () => clearInterval(interval);

    }, [])
    // const myDate = `${time.getDate()}.${time.getMonth()+1}.${time.getFullYear()}`;
    // const myTime = `${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}`;

    const handleLogOutClick = () => {
      dispatch(removeUser())
    }
    const navigate = useNavigate();

    const handleClick = () => {
      navigate(`/users/${myId}`);
  }


  return (
    <div className={classes.header}>
        <div className={classes.sidebar}>
        <div className={classes.date}>{time}</div>
        {/* <div className={classes.elem}> 
          <BiBell size={32} className={classes.button} />
        </div> */}
          <BiUser 
            size={32} 
            onClick={handleClick}
            className={classes.buttonU} />
            <div className={classes.username}>
            {myUser?.name} {myUser?.surName}
            </div>
          <button className={classes.button} onClick={handleLogOutClick}>{text}</button>
        </div>
    </div>
  )
}
